import { searchDaaqList } from "@/store/services/configuration/DaaqService";
import { Module } from "vuex";
import { NetworkState} from "@/types";
import { createNetwork, getNetworkNodeListByNetwork, searchPartyNetworkNodeList } from "@/store/services/configuration/NetworkService";


const NetworkModule: Module<NetworkState, unknown> = {
  namespaced: true,
  state: {
  },
  getters: {
   
  },
  actions: {
    networkNodeList({ commit }, payload) {
        console.log("NetworkModule")
        const { filter, callback } = payload;
        getNetworkNodeListByNetwork(filter)
          .then((res) => {
            callback(res);
          })
          .catch((e) => console.error(e));
    },
    searchPartyNetworkNodeList({ commit }, payload) {
        const { filter, callback } = payload;
        searchPartyNetworkNodeList(filter)
          .then((res) => {
            callback(res);
          })
          .catch((e) => console.error(e));
      },

      createNetwork({ commit }, payload) {
        const { networkPayload, callback } = payload;
        createNetwork(networkPayload)
          .then((res: any) => {
            callback(res);
          })
          .catch((e: any) => console.error(e));
      }
  },
  mutations: {

  },
};

export default NetworkModule;
