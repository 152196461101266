import i18n from "@/i18n";
import { createDocumentsType, searchDocumentsType, searchDocumentFamilyList, createDocumentsFamily, createAssociationMethode, getAssociation } from "@/store/services/configuration/documentsService";
import { DocumentsState} from "@/types";
import Notify from 'quasar/src/plugins/Notify.js';;
import { Module } from "vuex";

const documentsModule: Module<DocumentsState, unknown> = {
  namespaced: true,
  state: {
    selectedDocumentFamily:{
      objectType: "odm.supportingdocument.supportingdocumentfamily",
      systemUid: "odm-supportingdocument",
      resourceUid: '',
      reference:'',
      daaq:"/",
      isMandatory:false
      } 
  },
  getters: {
    getSelectedDocumentFamily: (state): any | undefined => {
      return state.selectedDocumentFamily;
    },

  },
  actions: {
    searchDocumentsType({ commit }, payload) {
        const { filter, callback } = payload;
        searchDocumentsType(filter)
          .then((res) => {
            callback(res);
          })
          .catch((e) => console.error(e));
      },
      createDocumentsType({ state, commit }, payload) {
        const { documentType, callback} = payload
        createDocumentsType(documentType)
                .then((res:any) => {
                    Notify.create({
                        message: i18n.global.t('main.dialog.success', { entity: res.data?.resourceUid }),
                        color: "positive",
                    })
                    callback(res);
                })
                .catch((e:any) => console.error(e))
          },
          searchDocumentFamilyList({ commit }, payload) {
            const { filter, callback } = payload;
            searchDocumentFamilyList(filter)
              .then((res) => {
                callback(res);
              })
              .catch((e) => console.error(e));
          },
          saveDocumentFamily({ commit }, payload) {
            const { documentFamily, callback} = payload
            documentFamily.resourceUid=documentFamily.reference
            delete documentFamily.reference
            delete documentFamily.isMandatory
            delete documentFamily.selectedDocuments            
            createDocumentsFamily(documentFamily)
                    .then((res:any) => {
                        Notify.create({
                            message: i18n.global.t('main.dialog.success', { entity: res.data?.resourceUid }),
                            color: "positive",
                        })
                        commit('setDocumentFamily', res.data);
                        
                    })
                    .catch((e:any) => console.error(e))
          },
          saveAssociation({ commit }, payload) {
            const { documentFamily} = payload
            createAssociationMethode(documentFamily)
                    .then((res:any) => {
                      commit('setDocumentFamily', res);
                    })
                    .catch((e:any) => console.error(e))
          },
          getAssociation({ commit }, payload) {
            getAssociation(payload)
                    .then((res:any) => {
                      commit('setDocumentFamily', res);
                    })
                    .catch((e:any) => console.error(e))
          },
          cleanState({ state, commit }) {
            commit('setInitial')
        },


  },
  mutations: {
    setDocumentFamily(state, payload) {
      state.selectedDocumentFamily = payload
      if(state.selectedDocumentFamily.isMandatory===undefined){
        state.selectedDocumentFamily.isMandatory=false
      }else{
        state.selectedDocumentFamily.isMandatory=payload.isMandatory==='true'
      }
      if(payload&&payload.resourceUid){
        state.selectedDocumentFamily.reference=payload.resourceUid

      }
      
  },
  setInitial(state) {
    state.selectedDocumentFamily = {
      objectType: "odm.supportingdocument.supportingdocumentfamily",
      systemUid: "odm-supportingdocument",
      resourceUid: '',
      reference:'',
      daaq:"/",
      isMandatory:false
      } 
}
   
  },
};

export default documentsModule;
