
import { createauthority, searchAuthority } from "@/store/services/configuration/AuthorityService";
import { AuthorityState } from "@/types";
import Notify from 'quasar/src/plugins/Notify.js';;
import { Module } from "vuex";


const AuthorityModule: Module<AuthorityState, any> = {
  namespaced: true,
  state: {
   
  },
  getters: {

  },
  actions: {
    searchAuthority({ commit }, payload) {
        const { filter, callback } = payload;
        searchAuthority(filter)
          .then((res) => {
            callback(res);
          })
          .catch((e) => console.error(e));
      },
      createauthority({ state, commit }, payload) {
        const { authority, callback} = payload
        createauthority(authority)
                .then((res:any) => {
                    Notify.create({
                        message: "Document type Created...",
                        color: "positive",
                    })
                    callback(res);
                })
                .catch((e:any) => console.error(e))
          },
  },
  mutations: {

  }
};
export default AuthorityModule;
