import { getUserByRole, searchDaaqList,saveDaaq, getDaaq } from "@/store/services/configuration/DaaqService";
import { DaaqState} from "@/types";
import Notify from 'quasar/src/plugins/Notify.js';;
import { Module } from "vuex";

const DaaqModule: Module<DaaqState, unknown> = {
  namespaced: true,
  state: {
    selectedDaaq:{
      label: '',
      readDAAQ: '/BODEMER/',
      createDAAQ: '/BODEMER/',
      defaultCreateDAAQ: '/BODEMER/',
      readAllowed: false,
      createAllowed: false,
      updateAllowed: false,
      deleteAllowed: false,
      objectType: "odm.user.daaq",
      systemUid: "odm-user",
      resourceUid: '',
      reference:''
    },
    edit:false
 
  },
  getters: {
   
  },
  actions: {
    cleanState({ state, commit }) {
      commit('setInitial')
  },
    searchDaaqList({ commit }, payload) {
        const { filter, callback } = payload;
        searchDaaqList(filter)
          .then((res) => {
            callback(res);
          })
          .catch((e) => console.error(e));
      },
    userByRole({ commit }, payload) {
      const { filter, callback } = payload;
      getUserByRole(filter)
        .then((res) => {
          callback(res);
        })
        .catch((e) => console.error(e));
    },
    saveDaaq({ commit ,state}, payload) {
      saveDaaq(payload,state.edit)
              .then((res:any) => {
                commit('setSelectedDaaq', res.data);
                commit('setDisable', true);
                Notify.create({
                  message: "save success",
                  color: "positive",
              })
              
              })
              .catch((err:any) => {
                  console.error(err);
                  Notify.create({
                    message: err,
                    color: "negative",
                  });
              })
    },
    getDaaq({ commit }, payload) {
      getDaaq(payload).then((res:any) => {
          commit('setSelectedDaaq', res.data);
          commit('setDisable', true);
        })
        .catch((e:any) => console.error(e))

    },
    
  },
  mutations: {
    setSelectedDaaq(state, payload) {
      state.selectedDaaq = payload
      state.selectedDaaq.reference=payload.resourceUid
  },
  setDisable(state, payload) {
      state.edit = payload
  },
  setInitial(state){
    state.selectedDaaq={
      label: '',
      readDAAQ: '/BODEMER/',
      createDAAQ: '/BODEMER/',
      defaultCreateDAAQ: '/BODEMER/',
      readAllowed: false,
      createAllowed: false,
      updateAllowed: false,
      deleteAllowed: false,
      objectType: "odm.user.daaq",
      systemUid: "odm-user",
      resourceUid: '',
      reference:''
    }
    state.edit=false
  }
  },
};

export default DaaqModule;
