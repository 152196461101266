import { api } from "@/auth/api";
import i18n from "@/i18n";
import {useApi} from "@/requests/useApi";
import {settings} from "@/settings";
import store from "@/store";
import {deepClone,unmountSelectFieldsV2, headers, Paths, UON,globalConfig} from "@/utils";
import {formatDateLocale} from "@/utils/configuration/formatters-config";
import axios from "axios";
import { getQcOrder } from "../filter/filterService";
import moment from "moment";
import { Picklist, QcHeader } from "@/types";

const api_query_url = settings.api_query_url;

export const saveOrderPayPlanObjective = async (selectedObjective:any) => {
    const payload = deepClone(selectedObjective)
    unmountSelectFieldsV2(payload, [
        "status",
        "frequency",
        "activity"
      ]);
    payload.frequency=payload.frequency? {resourceUid:payload.frequency} :null
    payload.status=payload.status? {resourceUid:payload.status} :null
    payload.activity=payload.activity? {resourceUid:payload.activity} :null
    payload.startPeriod=  payload.startPeriod ? formatDateLocale(payload.startPeriod, 'DD/MM/YYYY', 'fr'): ''
    payload.endPeriod=  payload.endPeriod ? formatDateLocale(payload.endPeriod, 'DD/MM/YYYY', 'fr'): ''
    const basis =handleBasisToSave(payload.basis)
    const eligibility =handleEligibilityToSave(payload.criterea,basis)
    const primes =handlePrimesToSave(payload.primes)
    delete payload.criterea
    delete payload.primesCriteria
    payload.primes=primes
    delete payload.basis
    payload.eligibility=eligibility
    //payload.eligibility.push(basis)
    const payPlanObjective =await createOrderPayPlanObjective(payload)
    return payPlanObjective
}
function handlePrimesToSave(primes:any){
  let primesFiltree = primes.filter((prime:any) => prime.criteria&&prime.criteria.resourceUid);
  for(const prime of primesFiltree){
    if(prime.model&&prime.model.resourceUid&&prime.model.resourceUid.id){
      prime.model.resourceUid=prime.model.resourceUid.id
    }
    delete prime.optionsList
    for (const detail of prime.details){
      if(detail.bloc&&detail.bloc.length>0){
        const variable =detail.bloc[0]
        if(variable.subCriteria&&variable.subCriteria.resourceUid&&variable.subCriteria.resourceUid.id){
          variable.subCriteria.resourceUid=variable.subCriteria.resourceUid.id
        }
        if(variable.targetValueOperator&&variable.targetValueOperator.resourceUid&&variable.targetValueOperator.resourceUid.id){
          variable.targetValueOperator.resourceUid=variable.targetValueOperator.resourceUid.id
        }
        if(variable.targetValueType&&variable.targetValueType.resourceUid&&variable.targetValueType.resourceUid.id){
          variable.targetValueType.resourceUid=variable.targetValueType.resourceUid.id
        }
        detail.subCriteria=variable.subCriteria
        detail.targetValueOperator=variable.targetValueOperator,
        detail.targetValue= variable.targetValue,
        detail.targetValueType=variable.targetValueType
        let primeSubDetails:any=[]
        if(detail.bloc.length>1){

          for (let i = 1; i < detail.bloc.length; i++){
            const subDetail=detail.bloc[i]
            if(subDetail.subCriteria&&subDetail.subCriteria.resourceUid&&subDetail.subCriteria.resourceUid.id){
              subDetail.subCriteria.resourceUid=subDetail.subCriteria.resourceUid.id
            }
            if(subDetail.targetValueOperator&&subDetail.targetValueOperator.resourceUid&&subDetail.targetValueOperator.resourceUid.id){
              subDetail.targetValueOperator.resourceUid=subDetail.targetValueOperator.resourceUid.id
            }
            if(subDetail.targetValueType&&subDetail.targetValueType.resourceUid&&subDetail.targetValueType.resourceUid.id){
              subDetail.targetValueType.resourceUid=subDetail.targetValueType.resourceUid.id
            }
            primeSubDetails.push(subDetail)

          }
          
        }
        detail.primeSubDetails=primeSubDetails
      }
      delete detail.bloc
    }
    delete prime.primes
  }
  return primesFiltree
}
function handleEligibilityToSave (criteria:any,basis:any){
 const eligibility :any=[]
 if(basis&&basis.criteria&&basis.criteria.resourceUid){
  eligibility.push(basis)
 for(const cri of criteria){
  if(cri.criteria && cri.criteria.resourceUid){
    handleDetailsToSave(cri)
    const eligibilitie:any ={
      objectType: "odm.order.payplanobjectiveeligibility",
      systemUid: "odm-order",
      targetValue: cri.targetValue,
      targetValueType:cri.targetValueType,
      basisFlag: true,
      criteria: cri.criteria,
      details: cri.details
    }
    if(cri.resourceuid){
      eligibilitie.resourceuid=cri.resourceuid
    }
    eligibility.push(eligibilitie)
  }
 }
 }
 


 return eligibility
}
function handleBasisToSave (payload:any){
 const basis= deepClone(payload)
 handleDetailsToSave(basis)
 const basisToSave:any={
  objectType: "odm.order.payplanobjectiveeligibility",
  systemUid: "odm-order",
  targetValue: basis.targetValue,
  targetValueType:basis.targetValueType,
  basisFlag: true,
  criteria: basis.criteria,
  details:basis.details
}
if(basis.resourceUid){
  basisToSave.resourceUid=basis.resourceUid
}
return basisToSave
}

function handleDetailsToSave(payload:any){
  for (const detail of payload.details){
    if(detail.value&& detail.value.type&&detail.value.type.id){
      detail.subCriteria ={
        objectType: "odm.order.subcriteriadefinition",
        systemUid: "odm-order",
        resourceUid: detail.value.type.id
    }
    }
    delete detail.optionsList
    delete detail.value
  }
}
export const handleGetOrderPayPlanObjective = async (resourceUid:any) => {
    const orderPayPlanObjective = await getOrderPayPlanObjective(resourceUid)
    const basis=orderPayPlanObjective.eligibility[0]
    const criterea=orderPayPlanObjective.eligibility.filter((item: any) => item.resourceUid!=basis.resourceUid)
    const initSelectedObjectif= store.getters["objectiveModule/initSelectedObjectif"]
    initSelectedObjectif.resourceUid=orderPayPlanObjective.resourceUid
    initSelectedObjectif.reference=orderPayPlanObjective.reference
    initSelectedObjectif.label=orderPayPlanObjective.label
    initSelectedObjectif.startPeriod=orderPayPlanObjective.startPeriod?orderPayPlanObjective.startPeriod:''
    initSelectedObjectif.endPeriod=orderPayPlanObjective.endPeriod?orderPayPlanObjective.endPeriod:''
    initSelectedObjectif.status=orderPayPlanObjective.status && orderPayPlanObjective.status.resourceUid ?orderPayPlanObjective.status.resourceUid:''
    initSelectedObjectif.frequency=orderPayPlanObjective.frequency&&orderPayPlanObjective.frequency.resourceUid?orderPayPlanObjective.frequency.resourceUid:''
    initSelectedObjectif.activity=orderPayPlanObjective.activity&&orderPayPlanObjective.activity.resourceUid?orderPayPlanObjective.activity.resourceUid:''
    if(basis){
       await handledetails(basis)
      if(basis&&basis.criteria&&basis.criteria.resourceUid){
        initSelectedObjectif.basis=basis
      }else{
        initSelectedObjectif.basis= store.getters["objectiveModule/getCritereaInit"]
      }
      
    }
    for(const criteria of criterea){
       await handledetails(criteria)
    }
    if(criterea.length>0){
      initSelectedObjectif.criterea=criterea
    }else{
      initSelectedObjectif.criterea=[]
    }
    
    for(const prime of orderPayPlanObjective.primes){
      for(const detail of prime.details){
        detail.bloc=[]
        detail.bloc.push({
            subCriteria:detail.subCriteria ,
            targetValueOperator:detail.targetValueOperator,
            targetValue: detail.targetValue,
            targetValueType:detail.targetValueType
          })
        if(detail.primeSubDetails&&detail.primeSubDetails.length>0){
          for(const primeSubDetail of detail.primeSubDetails){
            detail.bloc.push({
              subCriteria:primeSubDetail.subCriteria ,
              targetValueOperator:primeSubDetail.targetValueOperator,
              targetValue: primeSubDetail.targetValue,
              targetValueType:primeSubDetail.targetValueType,
              resourceUid:primeSubDetail.resourceUid
            })
          }


        }
        

      }
      if(prime.brand && prime.brand.resourceUid){
        await store.dispatch("objectiveModule/searchOrderSubCriteriaDefinition", {
          callback: (res: any) => {
            prime.optionsList=res
            },
          filter: {criteria_definition_id:prime.brand.resourceUid}
        });
      }
      if(!(prime.model && prime.model.resourceUid)){
        prime.model={
          objectType: "odm.order.subcriteriadefinition",
          systemUid: "odm-order",
          resourceUid: ''
          }
      }
    }
    initSelectedObjectif.primes=orderPayPlanObjective.primes
    return initSelectedObjectif

}
 async function handledetails(basis:any){
  for(const detail of basis.details){
    detail.value={type: {
      value: detail.subCriteria?detail.subCriteria.resourceUid:'',
      label: detail.subCriteria?detail.subCriteria.resourceUid:''
    }}
    if(detail.criteria&& detail.criteria.resourceUid){
      await store.dispatch("objectiveModule/searchOrderSubCriteriaDefinition", {
       callback: (res: any) => {
         detail.optionsList=res
         },
       filter: {criteria_definition_id:detail.criteria.resourceUid}
     });
   }
    

  }

}

//Api's PalyPlan
export const createOrderPayPlan = async (payload: any) => {
    return (await useApi(UON.SystemUid.odmOrder, `${Paths.order.orderPayPlan.create}`).upsert(payload)).response
}

export const getOrderPayPlan = async (resourceUid: any) => {
  return (await useApi(UON.SystemUid.odmOrder, `${Paths.order.orderPayPlan.get(resourceUid)}`).get()).response
}

export const deleteObjective = async (resourceUid: any) => {
  return (await useApi(UON.SystemUid.odmOrder, `payplan`).remove(resourceUid)).response
}


//

//Api's PalyPlanObjective
export const createOrderPayPlanObjective = async (payload: any) => {
  return (await useApi(UON.SystemUid.odmOrder, `${Paths.order.orderPayPlanObjective.create}`).upsert(payload)).response
}
export const getOrderPayPlanObjective = async (resourceUid: any) => {
    return (await useApi(UON.SystemUid.odmOrder, `${Paths.order.orderPayPlanObjective.get(resourceUid)}`).get()).response
}
//
export function searchOrderPayPlanObjective(filter: any,offset?: any, limit?: any): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
        const url = settings.api_query_url;
        const qc= getQcOrderPayPlanObjective(filter,offset,limit)
        const _headers = headers({
            qc: JSON.stringify(qc)
        })
        axios.get(url, {
            headers: _headers
        }).then(res => {
            resolve(res.data)
        }).catch(err => reject(err))
    })
}
export function getQcOrderPayPlanObjective(filter: any,offset?: any, limit?: any) {
    const qc_header: any = {
      qc: {
        queryId: "order-pay-plan-objective-search",
        offset: offset || 0,
        limit: limit || 100,
      },
    };
  
    qc_header.qc.parameters = {
      ...filter,
    };
    const operators:any = [];
    if (filter.reference) {
        operators.push(getOperator("lower(order_pay_plan_objective", "_reference)", "LIKE", globalConfig.formatters.getEqualValue(filter.reference + '%').toLowerCase(), ""))
    }
    if (filter.label) {
        operators.push(getOperator("lower(order_pay_plan_objective", "label)", "LIKE", globalConfig.formatters.getEqualValue(filter.label + '%').toLowerCase(), ""))
    }
    if (filter.activity && filter.activity.value) {
        operators.push(
            getOperator(
              "order_pay_plan_objective",
              "activity_code",
              "EQUAL",
              `'${filter.activity.value}'`,
              "activity_code"
            )
          );
    }
    if (filter.status) {
        operators.push(
            getOperator(
              "order_pay_plan_objective",
              "status_code",
              "EQUAL",
              `'${filter.status}'`,
              "status_code"
            )
          );
    }
    if (filter && filter.startPeriod) {
        operators.push(
          getOperator(
            "order_pay_plan_objective",
            "start_period",
            "GREATER_THAN_EQUAL",
            //formatDateLocale(filter.startPeriod, "DD/MM/YYYY", "fr").toString,
            globalConfig.formatters.getEqualValue(moment(filter.startPeriod,  'DD/MM/YYYY').format('YYYY-MM-DD')),
            "startPeriod"
          )
        );
      }
      if (filter && filter.endPeriod) {
        operators.push(
          getOperator(
            "order_pay_plan_objective",
            "end_period",
            "LESS_THAN_EQUAL",
            //formatDateLocale(filter.endPeriod, "DD/MM/YYYY", "fr").toString,
            globalConfig.formatters.getEqualValue(moment(filter.endPeriod,  'DD/MM/YYYY').format('YYYY-MM-DD')),
            "endPeriod"
          )
        );
      }
    
  
    if (operators.length > 0) {
      qc_header.qc.otherFilters = {
        expressions: [
          {
            and: {
              operators,
            },
          },
        ],
      };
    }
    return qc_header.qc;
  }

  export function searchOrderSubCriteriaDefinition(filter: any,offset?: any, limit?: any): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
        const url = settings.api_query_url;
        const qc= getQcOrderSubCriteriaDefinition(filter,offset,limit)
        const _headers = headers({
            qc: JSON.stringify(qc)
        })
        axios.get(url, {
            headers: _headers
        }).then(res => {
            resolve(res.data)
        }).catch(err => reject(err))
    })
}
  export function getQcOrderSubCriteriaDefinition(filter: any,offset?: any, limit?: any) {
    const qc_header: any = {
      qc: {
        queryId: "order-sub-criteria-definition",
        offset: offset || 0,
        limit: limit || 100,
      },
    };
  
    qc_header.qc.parameters = {
      ...filter,
    };
    const operators:any = [];
    if (filter.criteria_definition_id) {
        operators.push(getOperator("order_sub_criteria_definition", "criteria_definition_id", "EQUAL", globalConfig.formatters.getEqualValue(filter.criteria_definition_id), ""))
    }
    
    if (operators.length > 0) {
      qc_header.qc.otherFilters = {
        expressions: [
          {
            and: {
              operators,
            },
          },
        ],
      };
    }
    return qc_header.qc;
  }

   function getOperator(
    table: string,
    field: string,
    operator: string,
    value?: any,
    parameter?: string,
    inParameters?: string[]
  ) {
    return {
      expression: {
        table: table,
        field: field,
        operator: operator,
        value: value,
        parameter: parameter,
        inValue:
          inParameters && inParameters.length > 0
            ? {
                values: inParameters,
              }
            : null,
      },
    };
  }
   function getInOperator(
    table: string,
    field: string,
    operator: string,
    value: any,
    parameter: any,
    inParameters: string
  ) {
    return {
      expression: {
        table: table,
        field: field,
        operator: operator,
        value: value,
        parameter: parameter,
        inValue: {
          values: [inParameters],
        },
      },
    };
  }
export const searchObjectiveList = async (filter: any) => {
  const qc_header: QcHeader = {
    qc: {
      queryId: "search-objective-list",
      offset: 0,
      limit: 100
    }

  };

  const operators = []
  if (filter && filter.vendeurList&& filter.vendeurList.config && filter.vendeurList.config.vendor_id  != null) {
    operators.push(
      getOperator(
        "order_pay_plan", 
        "beneficiary_resourceuid", 
        "EQUAL", 
        globalConfig.formatters.getEqualValue(filter.vendeurList.config.vendor_id),
         "beneficiary_resourceuid"
        )
      )
  }
  if (filter && filter.annee && !filter.periodes) {
 
    const firstDay ='01'
    const month ='01'
    const startDate = `${filter.annee}-${month}-${firstDay}`;
    operators.push(
      getOperator(
        "order_pay_plan_objective",
        "start_period",
        "GREATER_THAN_EQUAL",
        globalConfig.formatters.getEqualValue(startDate),
        "start_period"
      )
    );
  }
  if (filter && filter.annee && !filter.periodes) {
 
    const firstDay ='01'
    const month ='12'
    const endDate = `${filter.annee}-${month}-${firstDay}`;
    operators.push(
      getOperator(
        "order_pay_plan_objective",
        "end_period",
        "LESS_THAN_EQUAL",
        globalConfig.formatters.getEqualValue(endDate),
        "end_period"
      )
    );
  }
  if (filter && filter.annee && filter.periodes) {
 
    const firstDay ='01'
    const startDate = `${filter.annee}-${filter.periodes.value}-${firstDay}`;
    operators.push(
      getOperator(
        "order_pay_plan_objective",
        "start_period",
        "GREATER_THAN_EQUAL",
        globalConfig.formatters.getEqualValue(startDate),
        "start_period"
      )
    );
  }
  if (filter && filter.annee && filter.periodes) {
    const year = parseInt(filter.annee, 10);
    const month = parseInt(filter.periodes.value, 10);
    const lastDay = new Date(year, month, 0).getDate();
    const endDate = `${filter.annee}-${filter.periodes.value}-${lastDay}`;
    operators.push(
      getOperator(
        "order_pay_plan_objective",
        "end_period",
        "LESS_THAN_EQUAL",
        globalConfig.formatters.getEqualValue(endDate),
        "end_period"
      )
    );
  }
  
  if (operators.length > 0) {
    qc_header.qc.otherFilters = {
      expressions: [
        {
          and: {
            operators
          }
        }
      ]
    }

  }

  const { data } = await api().get(api_query_url, { headers: headers({ qc: JSON.stringify(qc_header.qc) }) });
  return data
}
export const searchObjectivePrimeList = async (filter: any) => {
  const qc_header: QcHeader = {
    qc: {
      queryId: "search-objective-prime-list",
      offset: 0,
      limit: 100
    }

  };
 //reste a modifier le query "search-objective-prime-list" pour afficher les criteres
  const operators = []
  if (filter && filter.vendeurList&& filter.vendeurList.config && filter.vendeurList.config.vendor_id  != null) {
    operators.push(
      getOperator(
        "order_pay_plan", 
        "beneficiary_resourceuid", 
        "EQUAL", 
        globalConfig.formatters.getEqualValue(filter.vendeurList.config.vendor_id),
         "beneficiary_resourceuid"
        )
      )
  }
  if (filter && filter.annee && !filter.periodes) {
 
    const firstDay ='01'
    const month ='01'
    const startDate = `${filter.annee}-${month}-${firstDay}`;
    operators.push(
      getOperator(
        "order_pay_plan_objective",
        "start_period",
        "GREATER_THAN_EQUAL",
        globalConfig.formatters.getEqualValue(startDate),
        "start_period"
      )
    );
  }
  if (filter && filter.annee && !filter.periodes) {
 
    const firstDay ='01'
    const month ='12'
    const endDate = `${filter.annee}-${month}-${firstDay}`;
    operators.push(
      getOperator(
        "order_pay_plan_objective",
        "end_period",
        "LESS_THAN_EQUAL",
        globalConfig.formatters.getEqualValue(endDate),
        "end_period"
      )
    );
  }
  if (filter && filter.annee && filter.periodes) {
 
    const firstDay ='01'
    const startDate = `${filter.annee}-${filter.periodes.value}-${firstDay}`;
    operators.push(
      getOperator(
        "order_pay_plan_objective",
        "start_period",
        "GREATER_THAN_EQUAL",
        globalConfig.formatters.getEqualValue(startDate),
        "start_period"
      )
    );
  }
  if (filter && filter.annee && filter.periodes) {
    const year = parseInt(filter.annee, 10);
    const month = parseInt(filter.periodes.value, 10);
    const lastDay = new Date(year, month, 0).getDate();
    const endDate = `${filter.annee}-${filter.periodes.value}-${lastDay}`;
    operators.push(
      getOperator(
        "order_pay_plan_objective",
        "end_period",
        "LESS_THAN_EQUAL",
        globalConfig.formatters.getEqualValue(endDate),
        "end_period"
      )
    );
  }
  
  if (operators.length > 0) {
    qc_header.qc.otherFilters = {
      expressions: [
        {
          and: {
            operators
          }
        }
      ]
    }

  }

  const { data } = await api().get(api_query_url, { headers: headers({ qc: JSON.stringify(qc_header.qc) }) });
  return data
}