
import {Options, Vue} from "vue-class-component";


@Options({
  props: ["orderItems"],
  components: {
  },
})
export default class summaryTabOrderVehicle extends Vue {
  orderItems?: Array<any>;
    getAssets(orderItem: any) {
    let assetInfo = {}
    const asset = orderItem && orderItem.orderAssets && orderItem.orderAssets.length
            ? orderItem.orderAssets.filter((asset:any)=> asset.assetTradeIn === null)[0]
            : null;
    const brand = asset.brand ? asset.brand.replace(/asset.good.vehicle.brand./g, "")   : "-"
    const range = asset.range.includes('range.') ? asset.range.substring(asset.range.lastIndexOf(".") + 1) : "-" ;
    const version = asset.version.includes('range.') ? asset.version.substring(asset.version.lastIndexOf("range") + 6) : "-" ;
    const color = asset.color ? asset.color : "-"
    const chassisReference = asset.chassisReference ? asset.chassisReference : "-"
    const engineType = asset.engineType ? asset.engineType.replace(/asset.good.vehicle.energytype./g, "")  : "-"
    const matriculation = asset.matriculation ? asset.matriculation : "-"   
    const matriculationDate = asset.matriculationDate ? asset.matriculationDate : "-"   
    assetInfo = {
      "brand": brand+" "+range+" "+version,
       "color":color,
        "chassisReference": chassisReference,
        "engineType": engineType,
        "matriculation": matriculation,
        "matriculationDate": matriculationDate,
        "numFab": "-"
      }
   return assetInfo 
  }  
}
