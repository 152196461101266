import { api } from "@/auth/api";
import i18n from "@/i18n";
import {useApi} from "@/requests/useApi";
import {settings} from "@/settings";
import {globalConfig, headers, Paths, UON} from "@/utils";
import {formatDateLocale} from "@/utils/configuration/formatters-config";
import axios from "axios";
import { getQcOrder } from "../filter/filterService";
import moment from "moment";

const basePath = `${settings.api_url}/odm-product/api/1/odm-product`
const orderBasePath = `${settings.api_url}/odm-order/api/1/odm-order` 
const baseSupportingDocumentPath = `${settings.api_url}/odm-supportingdocument/api/1/odm-supportingdocument`

export const getParty = async (resourceUid: string, objectType: string) => {
    const path = objectType.includes('person') ? Paths.frenchPerson : Paths.frenchOrganization
    return (await useApi(UON.SystemUid.odmParty, path).get(resourceUid)).response
}

export const getOrder = async (resourceUid: any) => {
    return (await useApi(UON.SystemUid.odmOrder, `${Paths.order.get(resourceUid)}`).get()).response
}
export const duplicateOrder = async (resourceUid:any,number: any,length:any) => {
    const URI = `${settings.api_url}/${UON.SystemUid.odmOrchestration}/business-api/1/order/${resourceUid}/duplicate?duplicateTimes=${number}&length=${length}`;
    return api().post(URI)
}
export const getOrderOrchestration = async (resourceUid: any) => { 
    /*const URI = `http://localhost:9095/business-api/1/order/`+resourceUid+'/';
    return (await api().get(URI)).data;*/
    return (await useApi(UON.SystemUid.odmOrchestration, `${Paths.order.get(resourceUid)}`).get()).response 
 
 } 
export const deleteOrder = async (resourceUid: any) => {
    return (await useApi(UON.SystemUid.odmOrder, `order`).remove(resourceUid)).response
}
export const getNetwork = async (resourceUid: any) => {
    return (await useApi(UON.SystemUid.odmOrder, `${Paths.order.network.get(resourceUid)}`).get()).response
}
export const getNetworkNode = async (resourceUid: any) => {
    return (await useApi(UON.SystemUid.odmOrder, `${Paths.order.networkNode.get(resourceUid)}`).get()).response
}

export const getOrderParty = async (resourceUid: any) => {
    return (await useApi(UON.SystemUid.odmOrder, `${Paths.order.party.get(resourceUid)}`).get()).response
}

export const getOrderAssetDelivery = async (resourceUid: any) => {
    return (await useApi(UON.SystemUid.odmOrder, `${Paths.order.assetDelivery.get(resourceUid)}`).get()).response
}

export const createOrder = async (payload: any) => {
    return (await useApi(UON.SystemUid.odmOrder, `${Paths.order.create}`).upsert(payload)).response
}
export const createOrderOrch = async (payload: any) => {
    /*if (!payload.resourceUid) {
        const URI = `http://localhost:9095/business-api/1/order/`;
        return (await api().post(URI,payload)).data;
      }
      else {
        const URI = `http://localhost:9095/business-api/1/order/${payload.resourceUid}/`;
        return (await api().put(URI,payload)).data;
      }*/
     
    return (await useApi(UON.SystemUid.odmOrchestration, `${Paths.order.create}`).upsert(payload)).response
}

export const calculateMarginAPI = async (payload: any) => {
    const URI = `${settings.api_url}/${UON.SystemUid.odmOrder}/api/1/${UON.SystemUid.odmOrder}/calculation/margin/`;
    return api().post(URI, payload)
}

export const createOrderParty = async (payload: any) => {
    return (await useApi(UON.SystemUid.odmOrder, `${Paths.order.party.create}`).upsert(payload)).response
}

export const createNetwork = async (payload: any) => {
    return (await useApi(UON.SystemUid.odmOrder, `${Paths.order.network.create}`).upsert(payload)).response
}

export const createNetworkNode = async (payload: any) => {
    return (await useApi(UON.SystemUid.odmOrder, `${Paths.order.networkNode.create}`).upsert(payload)).response
}

export const createAssetDelivery = async (payload: any) => {
    return (await useApi(UON.SystemUid.odmOrder, `${Paths.order.assetDelivery.create}`).upsert(payload)).response
}
export const createAssetTradeIn = async (payload: any) => {
    return (await useApi(UON.SystemUid.odmOrder, `${Paths.order.assetTradeIn.create}`).upsert(payload)).response
}
export const getOrderAssetTradeIn = async (resourceUid: any) => {
    return (await useApi(UON.SystemUid.odmOrder, `${Paths.order.assetTradeIn.get(resourceUid)}`).get()).response
}
export const createOrderFinancing = async (payload: any) => {
    return (await useApi(UON.SystemUid.odmOrder, `${Paths.order.financing.create}`).upsert(payload)).response
}

export const createOrderAssociatedParty = async (parentResourceUid:string, payload: any) => {
    return (await useApi(UON.SystemUid.odmOrder, `${Paths.order.orderAssociatedParty.create(parentResourceUid)}`).upsert(payload)).response
}

export const createProductCategoryClass = async (parentResourceUid:string, payload: any) => {
    const URI = `${settings.api_url}/${UON.SystemUid.odmProduct}/api/1/${UON.SystemUid.odmProduct}/productcategory/${parentResourceUid}/productcategoryclass/`;
    return api().post(URI, payload)
}


export function getOperator(table: string, field: string, operator: string, value?: any, parameter?: string, inParameters?: string[]) {
    return {
        "expression": {
            "table": table,
            "field": field,
            "operator": operator,
            "value": value,
            "parameter": parameter,
            "inValue": (inParameters && inParameters.length > 0) ? {
                "values": inParameters
            } : null,
        }
    }
}

export function searchOrder(filter: any,offset?: any, limit?: any): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
        const url = settings.api_query_url;
        const qc= getQcOrder(filter,offset,limit)
        const _headers = headers({
            qc: JSON.stringify(qc)
        })
        axios.get(url, {
            headers: _headers
        }).then(res => {
            resolve(res.data)
        }).catch(err => reject(err))
    })
}
export async function getInventoryItem(inventoryItemCode: any) {
    return api().get(`${basePath}/inventoryitem/${inventoryItemCode}/`)
}

export async function updateInventoryItem(body:any, status:string, inventoryItemCode:string) {
    console.log("body ", body)
     const matriculationDate =  body.matriculationDate ? formatDateLocale(body.matriculationDate, i18n.global.t("mask.format"), 'en', 'YYYY-MM-DD') : ''
     const constructionDate = body.constructionDate ? formatDateLocale(body.constructionDate, i18n.global.t("mask.format"), 'en', 'YYYY-MM-DD') : ''
     body.matriculationDate= matriculationDate
     body.constructionDate= constructionDate
     body.status.resourceUid = status
     if(body.guarantee){
        const guaranteeDateFrom =  body.guarantee.from ? formatDateLocale(body.guarantee.from, i18n.global.t("mask.format"), 'en', 'YYYY-MM-DD') : ''
        const guaranteeDateUntil = body.guarantee.until ? formatDateLocale(body.guarantee.until, i18n.global.t("mask.format"), 'en', 'YYYY-MM-DD') : ''
        body.guarantee.from = guaranteeDateFrom
        body.guarantee.until = guaranteeDateUntil
     }
    return api().put(`${basePath}/inventoryitem/${inventoryItemCode}/`, body)
}
export async function getOrderItem(orderItemResourceUid: string) {
    return api().get(`${orderBasePath}/orderitem/${orderItemResourceUid}/`)
}
export async function updateOrderItem(body: any, previsionDeliveryDate: any, orderItemResourceUid: string ,color:string,effectiveDeliveryDate?:any) {
    const preDate =  moment(new Date(previsionDeliveryDate)).format('YYYY-MM-DD HH:mm:ss')
    if(effectiveDeliveryDate){
        const reelDate=moment(new Date(effectiveDeliveryDate)).format('YYYY-MM-DD HH:mm:ss')
        body.effectiveDeliveryDate = preDate
    }
    
     body.previsionDeliveryDate = preDate
     body.eventColor= color
     body.orderAssets[0].matriculationDate = moment(new Date(body.orderAssets[0].matriculationDate)).format('YYYY-MM-DD')
    return api().put(`${orderBasePath}/orderitem/${orderItemResourceUid}/`, body)
}


export async function updateDateOrderItem(body: any, previsionDeliveryDate: any, orderItemResourceUid: string ) {
    const preDate =   moment(previsionDeliveryDate,  'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
     body.previsionDeliveryDate = preDate
    
    return api().put(`${orderBasePath}/orderitem/${orderItemResourceUid}/`, body)
}


export async function updateOrderStatus(orderResourceUid:any, status:any) {
    return api().put(`${orderBasePath}/updateOrderStatus/${orderResourceUid}/`, status)
}

export async function createOrderAlert(body: any, orderResourceUid: any ) {
    return  (await api().post(`${orderBasePath}/order/${orderResourceUid}/orderalert/`, body))
}
export async function updateOrderAlert(body: any, resourceUid: any) {
    return (await api().put(`${orderBasePath}/orderalert/${resourceUid}/`, body))
}
export async function deleteOrderAlert( resourceUid: any) {
    return (await api().delete(`${orderBasePath}/orderalert/${resourceUid}/`))
}
export const moveDocument = async (processUid:any,processItemUid:any,receivedFileUid: any,body:any) => {
    (await api().post(`${baseSupportingDocumentPath}/process/${processUid}/document/${processItemUid}/file/${receivedFileUid}/move/`, body))
}
export const removeReceivedFile = async (processUid:any,receivedFileUid: any) => {
    (await api().delete(`${baseSupportingDocumentPath}/process/${processUid}/file/${receivedFileUid}/`))
}
