import i18n from "@/i18n";
import { createbusinessrulevalueconfig, createdocumentvalueconfig, getbusinessrulevalueconfig, getdocumentvalueconfig, getFieldvalueconfig, saveFieldvalueconfig, searchCheckPointsConfig, updatebusinessrulevalueconfig, updatedocumentvalueconfig } from "@/store/services/configuration/ActionService";
import { createActionTrigger } from "@/store/services/configuration/ActionService";
import { ActionState} from "@/types";
import Notify from 'quasar/src/plugins/Notify.js';;
import { Module } from "vuex";

const ActionModule: Module<ActionState, unknown> = {
  namespaced: true,
  state: {
    selectedCheckPointConfig:{
      fieldObjectType: '',
      type: {objectType: "odm.supportingdocument.fieldvaluetypeconfig",
              systemUid: "odm-supportingdocument",
              resourceUid: ''
            },
      i18nLabel: '',
      checkOrder: 0,
      enable: true,
      deadline: {
      duration: 21,
        unit: "DAYS"
      },
      triggerAction: {objectType: "odm.supportingdocument.triggeractionconfig.actionqueryconfig",
                      systemUid: "odm-supportingdocument",
                      resourceUid: ''
                      },
      objectType: "odm.supportingdocument.checkpointsconfig.fieldvalueconfig",
      systemUid: "odm-supportingdocument",
      resourceUid: '',
    },
    selectedCheckPointConfigDocument:{
      i18nLabel: '',
      checkOrder: 0,
      enable: true,
      deadline: {
      duration: 21,
        unit: "DAYS"
      },
      objectType: "odm.supportingdocument.checkpointsconfig.documentvalueconfig",
      systemUid: "odm-supportingdocument",
    },
    selectedbusinessrulevalueconfig:{
      i18nLabel: '',
      checkOrder: 0,
      enable: true,
      deadline: {
      duration: 21,
        unit: "DAYS"
      },
      objectType: "odm.supportingdocument.checkpointsconfig.businessrulevalueconfig",
      systemUid: "odm-supportingdocument",
      businessRuleValue: null    
    },
    type: { label: "Document", value:"Document" },
    edit:false
  },
  getters: {
    getSelectedCheckPointConfig: (state): any | undefined => {
      return state.selectedCheckPointConfig;
    },

  },
  actions: {
    cleanState({ state, commit }) {
      commit('setInitial')
  },
    createActionTrigger({ commit }, payload) {
      createActionTrigger(payload)
              .then((res:any) => {
              })
              .catch((e:any) => console.error(e))
    },
    createFieldvalueconfig({ commit ,state}, payload) {
      saveFieldvalueconfig(payload,state.edit)
              .then((res:any) => {
                commit('setSelectedCheckPointConfig', res.data);
                Notify.create({
                  message: i18n.global.t('main.dialog.success', { entity: res.data?.resourceUid }),
                  color: "positive",
              })
              
              })
              .catch((err:any) => {
                  console.error(err);
                  Notify.create({
                    message: err,
                    color: "negative",
                  });
              })
    },
    searchCheckPointsConfig({ commit }, payload) {
      const { filter, callback } = payload;
      searchCheckPointsConfig(filter)
        .then((res) => {
          callback(res);
        })
        .catch((e) => console.error(e));
    },
    saveDocumentvalueconfig({ commit }, payload) {
      const { documentvalueconfig, callback} = payload
      documentvalueconfig.documentFamily = { resourceUid : documentvalueconfig.documentFamily.value,
        objectType: "odm.supportingdocument.supportingdocumentfamily"
      }
      createdocumentvalueconfig(documentvalueconfig)
      .then((res:any) => {
        res.data.documentFamily={ value : res.data.documentFamily.resourceUid,
          label: res.data.documentFamily.resourceUid
        }
        commit('setSelectedCheckPointConfigDocument', res.data);
        commit('setDisable', true);
        Notify.create({
          message: "save success",
          color: "positive",
      })
      
      })
      .catch((err:any) => {
          console.error(err);
          Notify.create({
            message: err,
            color: "negative",
          });
      })
    },
    updateDocumentvalueconfig({ commit }, payload) {
      const { documentvalueconfig, callback} = payload
      documentvalueconfig.documentFamily = { resourceUid : documentvalueconfig.documentFamily.value,
        objectType: "odm.supportingdocument.supportingdocumentfamily"
      }
      updatedocumentvalueconfig(documentvalueconfig)
      .then((res:any) => {
        res.data.documentFamily={ value : res.data.documentFamily.resourceUid,
          label: res.data.documentFamily.resourceUid
        }
        commit('setSelectedCheckPointConfigDocument', res.data);
        Notify.create({
          message: i18n.global.t('main.dialog.success', { entity: res.data?.resourceUid }),
          color: "positive",
      })
      
      })
      .catch((err:any) => {
          console.error(err);
          Notify.create({
            message: err,
            color: "negative",
          });
      })
    },
    getCheckPointconfig({ commit }, payload) {
      if(payload&&payload.checkpointsdiscriminator&&payload.checkpointsdiscriminator==='supportingdocument-FieldValueConfig'){
        getFieldvalueconfig(payload.checkpointsid).then((res:any) => {
          commit('setSelectedCheckPointConfig', res.data);
          commit('setType', { label: "Field", value: "Field" });
          commit('setDisable', true);
        })
        .catch((e:any) => console.error(e))
      }

      else if(payload&&payload.checkpointsdiscriminator&&payload.checkpointsdiscriminator==='supportingdocument-DocumentValueConfig'){
        getdocumentvalueconfig(payload.checkpointsid).then((res:any) => {
          res.data.documentFamily={ value : res.data.documentFamily.resourceUid,
            label: res.data.documentFamily.resourceUid
          }
          commit('setSelectedCheckPointConfigDocument', res.data);
          commit('setType', { label: "Document", value: "Document" });
          commit('setDisable', true);
        })
        .catch((e:any) => console.error(e))
      }

      else if(payload&&payload.checkpointsdiscriminator&&payload.checkpointsdiscriminator==='supportingdocument-BusinessRuleValueConfig'){
        getbusinessrulevalueconfig(payload.checkpointsid).then((res:any) => {
          if(res.data.businessRuleValue){
            res.data.businessRuleValue = JSON.stringify(res.data.businessRuleValue.rule)
          }
          else {
            res.data.businessRuleValue = ""
          }
          commit('setSelectedbusinessrulevalueconfig', res.data);
          commit('setType', { label: "Business Rule", value: "Business Rule" });
          commit('setDisable', true);
        })
        .catch((e:any) => console.error(e))
      }
    },

    saveBusinessRuleValueconfig({ commit }, payload) {
      const { businessRuleValueconfig, callback } = payload;
      let businessRuleValue = businessRuleValueconfig.businessRuleValue;
      if (!businessRuleValue || businessRuleValue === '') {
        businessRuleValueconfig.businessRuleValue = null;
      } else {
        try {
          if (typeof businessRuleValue === 'string') {
            let parsedValue = JSON.parse(businessRuleValue);

            if (typeof parsedValue === 'object' && parsedValue !== null) {
              businessRuleValueconfig.businessRuleValue = {
                  rule: parsedValue,
                  ruleEngineId:null,
              } 
            } else {
              throw new Error('Invalid JSON structure');
            }
          }

          else if (typeof businessRuleValue === 'object') {
            let stringifiedValue = JSON.stringify(businessRuleValue);
            let parsedValue = JSON.parse(stringifiedValue);

            if (typeof parsedValue === 'object' && parsedValue !== null) {
              businessRuleValueconfig.businessRuleValue = {
                rule: parsedValue,
                ruleEngineId:null,
            } 
            } else {
              throw new Error('Invalid JSON structure');
            }
          }
        } catch (e) {
          Notify.create({
            message: i18n.global.t('documentsType.dialog.validation.invalidJson'),
            color: 'negative',
          });
          return;
        }
      }
      createbusinessrulevalueconfig(businessRuleValueconfig)
      .then((res:any) => {
        if(res.data.businessRuleValue){
          res.data.businessRuleValue = JSON.stringify(res.data.businessRuleValue.rule)
        }
        else {
          res.data.businessRuleValue = ""
        }
        commit('setSelectedbusinessrulevalueconfig', res.data);
        commit('setDisable', true);
        Notify.create({
          message: i18n.global.t('main.dialog.success', { entity: res.data?.resourceUid }),
          color: "positive",
      })
      
      })
      .catch((err:any) => {
          console.error(err);
          Notify.create({
            message: err,
            color: "negative",
          });
      })
    },
    updateBusinessRuleValueconfig({ commit }, payload) {
      const { businessRuleValueconfig, callback} = payload
      let businessRuleValue = businessRuleValueconfig.businessRuleValue;
      if (!businessRuleValue || businessRuleValue === '') {
        businessRuleValueconfig.businessRuleValue = null;
      } else {
        try {
          if (typeof businessRuleValue === 'string') {
            let parsedValue = JSON.parse(businessRuleValue);

            if (typeof parsedValue === 'object' && parsedValue !== null) {
              businessRuleValueconfig.businessRuleValue = {
                rule: parsedValue,
                ruleEngineId:null,
            } 
            } else {
              throw new Error('Invalid JSON structure');
            }
          }

          else if (typeof businessRuleValue === 'object') {
            let stringifiedValue = JSON.stringify(businessRuleValue);
            let parsedValue = JSON.parse(stringifiedValue);

            if (typeof parsedValue === 'object' && parsedValue !== null) {
              businessRuleValueconfig.businessRuleValue = {
                rule: parsedValue,
                ruleEngineId:null,
            } 
            } else {
              throw new Error('Invalid JSON structure');
            }
          }
        } catch (e) {
          Notify.create({
            message: i18n.global.t('documentsType.dialog.validation.invalidJson'),
            color: 'negative',
          });
          return;
        }
      }
      updatebusinessrulevalueconfig(businessRuleValueconfig)
      .then((res:any) => {
        if(res.data.businessRuleValue){
          res.data.businessRuleValue = JSON.stringify(res.data.businessRuleValue.rule)
        }
        else {
          res.data.businessRuleValue = ""
        }
        commit('setSelectedbusinessrulevalueconfig', res.data);
        commit('setDisable', true);
        Notify.create({
          message: i18n.global.t('main.dialog.success', { entity: res.data?.resourceUid }),
          color: "positive",
      })
      
      })
      .catch((err:any) => {
          console.error(err);
          Notify.create({
            message: err,
            color: "negative",
          });
      })
    },

  },
  mutations: {
    setSelectedCheckPointConfig(state, payload) {
      state.selectedCheckPointConfig = payload
  },
  setSelectedCheckPointConfigDocument(state, payload) {
    state.selectedCheckPointConfigDocument = payload
},
  setSelectedbusinessrulevalueconfig(state, payload) {
    state.selectedbusinessrulevalueconfig = payload
  },
setType(state, payload) {
  state.type = payload
},
  setDisable(state, payload){
    state.edit=payload
  },
  setInitial(state, payload){
    state.selectedCheckPointConfig={
      fieldObjectType: '',
      type: {objectType: "odm.supportingdocument.fieldvaluetypeconfig",
              systemUid: "odm-supportingdocument",
              resourceUid: ''
            },
      i18nLabel: '',
      checkOrder: 0,
      enable: true,
      deadline: {
      duration: 21,
        unit: "DAYS"
      },
      triggerAction: {objectType: "odm.supportingdocument.triggeractionconfig.actionqueryconfig",
                      systemUid: "odm-supportingdocument",
                      resourceUid: ''
                      },
      objectType: "odm.supportingdocument.checkpointsconfig.fieldvalueconfig",
      systemUid: "odm-supportingdocument",
      resourceUid: '',
    }
    state.selectedCheckPointConfigDocument={
      i18nLabel: '',
      checkOrder: 0,
      enable: true,
      deadline: {
      duration: 21,
        unit: "DAYS"
      },
      objectType: "odm.supportingdocument.checkpointsconfig.documentvalueconfig",
      systemUid: "odm-supportingdocument",
    },
    state.selectedbusinessrulevalueconfig={
      i18nLabel: '',
      checkOrder: 0,
      enable: true,
      deadline: {
      duration: 21,
        unit: "DAYS"
      },
      objectType: "odm.supportingdocument.checkpointsconfig.businessrulevalueconfig",
      systemUid: "odm-supportingdocument",
      businessRuleValue: null
    },
    state.type= { label: "Document", value:"Document" },
    state.edit=false
  }

  },
};

export default ActionModule;
