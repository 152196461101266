import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-469faef5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "tw-document-wrapper z-top controls-background" }
const _hoisted_3 = { class: "card-header bg-white d-flex justify-content-between align-items-center pl-3 pr-2" }
const _hoisted_4 = { class: "card-title mb-0 d-flex align-items-center" }
const _hoisted_5 = { class: "font-weight-bold" }
const _hoisted_6 = { class: "card-title mb-0 d-flex align-items-end" }
const _hoisted_7 = { class: "row card-body tw-document-container scrollable-content" }
const _hoisted_8 = { class: "col-12" }
const _hoisted_9 = { class: "text-right" }
const _hoisted_10 = { class: "pt-0" }
const _hoisted_11 = { class: "table-responsive" }
const _hoisted_12 = { class: "table-title" }
const _hoisted_13 = { class: "d-flex justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_MessageAlert = _resolveComponent("MessageAlert")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_ctx.show)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("span", _hoisted_5, _toDisplayString(`${_ctx.$t("printDocument.reference")}: ${_ctx.reference}`), 1)
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_q_icon, {
                  name: "chevron_right",
                  size: "20px",
                  onClick: _ctx.toggleShowControls
                }, null, 8, ["onClick"])
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_MessageAlert, { ref: "alertMessage" }, null, 512),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_q_input, {
                  type: "textarea",
                  dense: "",
                  outlined: "",
                  label: _ctx.$t('Analysis.Comment'),
                  class: "w-100 mt-3",
                  rows: "1",
                  modelValue: _ctx.comment,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.comment) = $event))
                }, null, 8, ["label", "modelValue"]),
                _createElementVNode("div", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
                    return (_openBlock(), _createBlock(_component_q_checkbox, {
                      key: index,
                      modelValue: item.selected,
                      "onUpdate:modelValue": ($event: any) => ((item.selected) = $event),
                      label: _ctx.$t('order.alertList.'.concat(item.name))
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "label"]))
                  }), 128))
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_q_btn, {
                    label: _ctx.$t('main.dossier.asset.command.add'),
                    class: "btn btn-primary",
                    disable: !_ctx.isAnyCheckboxSelected(),
                    onClick: _ctx.onSaveAlert
                  }, null, 8, ["label", "disable", "onClick"])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("h5", null, _toDisplayString(_ctx.$t("order.alertList.title")), 1)
                    ]),
                    _createVNode(_component_q_table, {
                      class: "shadow-0 table table-sm",
                      rows: _ctx.rows,
                      columns: _ctx.columns,
                      "row-key": "id",
                      "no-data-label": 
                    _ctx.$t('task.historiesStep.headers.histories.noDataMessage')
                  
                    }, {
                      header: _withCtx((props) => [
                        _createVNode(_component_q_tr, { props: props }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
                              return (_openBlock(), _createBlock(_component_q_th, {
                                key: col.name,
                                props: props
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t(col.label)), 1)
                                ]),
                                _: 2
                              }, 1032, ["props"]))
                            }), 128)),
                            _createVNode(_component_q_th, { "auto-width": "" })
                          ]),
                          _: 2
                        }, 1032, ["props"])
                      ]),
                      body: _withCtx((props) => [
                        _createVNode(_component_q_tr, { props: props }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_td, {
                              key: "createdBy",
                              props: props
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(props.row.whoCreated), 1)
                              ]),
                              _: 2
                            }, 1032, ["props"]),
                            _createVNode(_component_q_td, {
                              key: "creationDate",
                              props: props
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(props.row.whenCreated), 1)
                              ]),
                              _: 2
                            }, 1032, ["props"]),
                            _createVNode(_component_q_td, {
                              key: "comment",
                              props: props
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(props.row.comment), 1)
                              ]),
                              _: 2
                            }, 1032, ["props"]),
                            _createVNode(_component_q_td, {
                              key: "reasons",
                              props: props
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translateReasons(props.row.reasons)), 1)
                              ]),
                              _: 2
                            }, 1032, ["props"]),
                            _createVNode(_component_q_td, { "auto-width": "" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_q_btn, {
                                  flat: "",
                                  round: "",
                                  icon: "delete",
                                  color: "red",
                                  onClick: ($event: any) => (_ctx.deleteAlert(props.row , props.rowIndex))
                                }, null, 8, ["onClick"])
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["props"])
                      ]),
                      _: 1
                    }, 8, ["rows", "columns", "no-data-label"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createVNode(_component_q_btn, {
                    class: "btn text-body mr-2",
                    color: "transparent",
                    label: _ctx.$t('main.confirmationDialog.cancelButton'),
                    onClick: _ctx.toggleShowControls
                  }, null, 8, ["label", "onClick"])
                ])
              ])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}