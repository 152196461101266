import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  class: "row mt-4 mb-2 font-weight-bold",
  style: {"font-size":"16px","text-decoration":"underline"}
}
const _hoisted_2 = {
  key: 0,
  class: "row"
}
const _hoisted_3 = { class: "font-weight-bold" }
const _hoisted_4 = {
  key: 1,
  class: "row"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_checkbox = _resolveComponent("q-checkbox")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('demand.tabs.features')), 1),
    (!_ctx.orderConditions?.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t("order.orderConditions.NoConditionFound")), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.orderConditions?.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderConditions, (condition, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: condition.id
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(index>0 ? 'col-3 mb-1 ml-3' : 'col-3 mb-1')
              }, [
                (condition.checked)
                  ? (_openBlock(), _createBlock(_component_q_checkbox, {
                      key: 0,
                      modelValue: condition.checked,
                      "onUpdate:modelValue": ($event: any) => ((condition.checked) = $event),
                      label: _ctx.$t('order.orderConditions.list.'+condition.condition.resourceUid),
                      dense: "",
                      disable: "",
                      class: "font-size-12"
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "label"]))
                  : _createCommentVNode("", true)
              ], 2)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}