
import store from "@/store";
import {Options, Vue} from "vue-class-component";
import AlertDialog from "./AlertDialog.vue";
import { useRoute } from "vue-router";
import { globalConfig } from "@/utils";



@Options({
  components: { AlertDialog }
})
export default class AlertButton extends Vue {

  toggleShowControls() {
    return store.dispatch('orderModule/toggleShow')
  }


  get toggleShow(){
  return store.state.orderModule.showAlert
}
get order() {
    if( this.currentRoute === 'TaskDetail'){
        return store.state.taskModule.order
    }
    else{
        return store.state.orderModule.order;
    }
  }
  get recoverState() {
     const orderState = localStorage.getItem(globalConfig.storageKeys.orderTask)
     const orderResourceUid = orderState ? JSON.parse(orderState).resourceUid : ""
    return orderResourceUid;
  }

  get currentRoute() {
    return useRoute().name;
  }
  
get alertList(){
return store.state.orderModule.alertList
}
created(){
  store.dispatch("orderModule/getOrderAlertList", {
      filter: {
        orderId: this.order && this.order.resourceUid ? this.order.resourceUid : this.recoverState,
      },
    });
  }
}
