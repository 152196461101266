
  import { AbsenceManagementState } from "@/types";
  import { Module } from "vuex";
  import Notify from 'quasar/src/plugins/Notify.js';;
import { deleteAbsence, handleGetAbsence, saveAbsence, searchAbsenceList } from "@/store/services/AbsenceManagement/AbsenceManagementService";
import i18n from "@/i18n";
  
  const AbsenceManagementModule: Module<AbsenceManagementState, any> = {
    namespaced: true,
    state: {
     
    },
    getters: {

    },
    actions: {
        saveAbsence({ state, dispatch }, payload) {
            saveAbsence(state.selectedAbsence).then((res: any) => {
              if (payload && payload.callback) {
                  payload.callback(res)
              }
              if(res?.resourceUid){
                  Notify.create({
                      message: i18n.global.t("order.payplan.messages.createSuccess"),
                      color: "positive",
                  })
                  dispatch('getAbsenceEditMode', {
                      resourceUid: res.resourceUid
                  })
              }else{
                  Notify.create({
                      message: 'ERROR: ' + res,
                      color: 'negative'
                  });
              }
            })
          },
          searchAbsenceList({ commit }, payload) {
            const { filter, callback } = payload;
            searchAbsenceList(filter)
              .then((res) => {
                callback(res);
              })
              .catch((e) => console.error(e));
          },
          async getAbsenceEditMode({ commit}, payload) {
            const { resourceUid } = payload
            if (!resourceUid) {
                throw new Error('ResourceUid is mandatory!')
            }
            try {
                const absence = await handleGetAbsence(resourceUid)
                commit('setSelectedAbsence', absence);
            } catch (e) {
                Notify.create({
                    message: 'ERROR: ' + e,
                    color: 'negative'
                })
            }
          },
          deleteAbsence({ commit }, payload) {
            const { resourceUid } = payload
            deleteAbsence(resourceUid)
                .then((res:any) => {
                    Notify.create({
                        message: i18n.global.t("order.messages.deleteAbsenceSuccess"),
                        color: "positive",
                    })
                    
                })
                .catch((err:any) => {
                    console.error(err);
                    Notify.create({
                      message: err,
                      color: "negative",
                    });
                })
        },
    },
    mutations: {
    setSelectedAbsence(state,payload){
        state.selectedAbsence=payload
    },
    setMode(state,payload){
      state.mode=payload
  },
    }
  };
  export default AbsenceManagementModule;
  