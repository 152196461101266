import {
    ComboBox,
    IHistoricMessage,
    Message,
    objectTypeGroup,
    objectTypeUser,
    systemUidOdmContactmechanism,
    systemUidOdmUser,
    transferMsg,
} from "@/types";
import {globalConfig, headers, orderingByDateTime} from "@/utils";
import axios from "axios";
import {settings} from "@/settings";
import {api} from "@/auth/api";
import store from "@/store";
import {getHistoricMessagesApi} from "@/store/services/task/contactMechanism";

const api_query_url = settings.api_query_url;

const baseUrlGenerated = `${settings.api_url}/${systemUidOdmContactmechanism}/api/1/event/${systemUidOdmContactmechanism}`;

/**
 * this function runs the provided XML query to gettings subject's id susing Axios
 * and returns a Promise that resolves with the query result
 */
export function getSubjectsGeneric(): Promise<ComboBox> {
    const qc_header: any = {
        qc: {
            queryId: "message-subjects-generic",
        },
    };
    return new Promise<ComboBox>((resolve, reject) => {
        const _headers = headers({
            qc: JSON.stringify(qc_header.qc),
        });
        axios
            .get(api_query_url, {
                headers: _headers,
            })
            .then((res) => {
                const response = {
                    systemUid: systemUidOdmContactmechanism,
                    items: res.data.map((resItem: any) => {
                        return {
                            id: resItem.id,
                        };
                    }),
                };

                resolve(response);
            })
            .catch((err) => reject(err));
    });
}

export function getUsersGeneric(): Promise<ComboBox> {
    const qc_header: any = {
        qc: {
            queryId: "users-leaseforge-generic",
            limit: 99999
        },
    };
    const operators = []
    operators.push(getOperator("user_lease_forge_user", "enabled", "EQUAL", true, "enabled"))
    operators.push(getOperator("user_daaq", "read_allowed", "EQUAL", true, "read_allowed"))

    if ( operators.length > 0 ) {
        qc_header.qc.otherFilters = {
            expressions: [
                {
                    and: {
                        operators
                    }
                }
            ]
        }
    }
    return new Promise<ComboBox>((resolve, reject) => {
        const _headers = headers({
            qc: JSON.stringify(qc_header.qc),
        });
        axios
            .get(api_query_url, {
                headers: _headers,
            })
            .then((res) => {
                const response = {
                    systemUid: systemUidOdmUser,
                    items: res.data.map((user: any) => {
                        return {
                            objectType: objectTypeUser,
                            systemUid: systemUidOdmUser,
                            resourceUid: user.id,
                            firstName: user.first_name,
                            lastName: user.last_name,
                            username: user.username,
                            email: user.email,
                            read_daaq: user.read_daaq
                        };
                    }),
                };
                resolve(response);
            })
            .catch((err) => reject(err));
    });
}

export function getOperator(table: string, field: string, operator: string, value: any, parameter: string) {
    return {
        "expression": {
            "table": table,
            "field": field,
            "operator": operator,
            "value": value,
            "parameter": parameter
        }
    }
}

export function getGroupsGeneric(): Promise<ComboBox> {
    const qc_header: any = {
        qc: {
            queryId: "groups-security-generic",
            limit: 99999
        },
    };
    const operators = []
    operators.push(getOperator("user_security_group", "id", "LIKE", globalConfig.formatters.getEqualValue('GRP_' + '%'), "id"))
    operators.push(getOperator("user_daaq", "read_allowed", "EQUAL", true, "read_allowed"))

    if ( operators.length > 0 ) {
        qc_header.qc.otherFilters = {
            expressions: [
                {
                    and: {
                        operators
                    }
                }
            ]
        }
    }
    return new Promise<ComboBox>((resolve, reject) => {
        const _headers = headers({
            qc: JSON.stringify(qc_header.qc),
        });
        axios
            .get(api_query_url, {
                headers: _headers,
            })
            .then((res) => {
                const response = {
                    systemUid: systemUidOdmUser,
                    items: res.data.map((group: any) => {

                        return {
                            objectType: objectTypeGroup,
                            systemUid: systemUidOdmUser,
                            resourceUid: group.id,
                            read_daaq: group.read_daaq
                        };
                    }),
                };

                resolve(response);
            })
            .catch((err) => {
                    console.error(err)
                    reject(err)
                }
            );
    });
}

export const sendMessageService = async (request: Message) => {
    // /api/1/event/{systemUid}/message/
    return new Promise<Message>(async (resolve, reject) => {
        try {
            const result = await api().post(`${baseUrlGenerated}/message/`, request);
            resolve(result.data);
        } catch (e) {
            reject(e);
        }
    });
};

/**
 * Function to send a reply message to a system event.
 *
 * @async
 * @function replyMessageService
 * @param {Message} request - The message to be sent as a reply.
 * @param {string} msgUID - The unique identifier of the message to be replied to.
 * @returns {Promise<Message>} A Promise that resolves with the sent message data upon successful API call or rejects with an error message upon failure.
 * @throws {Error} An error object containing details about the failed API call.
 */
export const replyMessageService = async (request: Message) => {
    // /api/1/event/{systemUid}/message/{msgUID}/reply/
    const msgUID = store.state.notificationModule?.msgUID;
    return new Promise<Message>(async (resolve, reject) => {
        try {
            const result = await api().post(
                `${baseUrlGenerated}/message/${msgUID}/reply/`,
                request
            );
            resolve(result.data);
        } catch (e) {
            reject(e);
        }
    });
};

/**
 * transfer message service
 * @param payload
 * @param msgUID
 * @returns {Promise<Message>} A Promise that resolves with the sent message data upon successful API call or rejects with an error message upon failure.
 * @throws {Error} An error object containing details about the failed API call.
 */
export const transferMessage = async (payload: transferMsg) => {
    //const odmUserUid = store.state.authModule?.user.userId
    //const msgUID = store.state.notificationModule?.msgUID;
    if ( payload.recipients ) {
        payload.recipients.forEach((element: any) => {
            delete element.recipient.read_daaq
        });
    }
    return new Promise<Message>(async (resolve, reject) => {
        try {
            const result = await api().post(
                `${baseUrlGenerated}/message/${payload.resourceUid}/transfer/`,
                payload
            );
            console.info(result.data)
            return resolve(result.data)
        } catch (error) {
            console.error(error)
            return reject(error);
        }
    });
}


const mountRecipient = (items: any) => {
    const merged: any[] = [];
    let recipients: string[] = [];
    items.forEach((item: any) => {
        function pushRecipient() {
            recipients.push(item.recipientname)
            item.recipients = recipients;
        }

        if ( merged?.find(el => el.resourceuid === item?.resourceuid) ) {
            pushRecipient();
        }
        else {
            recipients = [];
            item.recipients = [];
            pushRecipient();
            delete item.recipientname;
            merged.push(item);
        }
    });
    return merged;
}

export const getHistoricMessages = async (payload: { type: string, entityId: string }) => {
    const messagePackList: { messagePack: IHistoricMessage[]; }[] = [];
    let messagePack: IHistoricMessage[] = [];

    (orderingByDateTime.ascending((await getHistoricMessagesApi(payload)).data) as IHistoricMessage[]).filter(
        (m: IHistoricMessage) => {
            if ( !m.replymsgparent && !m.transfermsgparent ) {
                messagePack = [];
                messagePack.push(m);
                messagePackList.push({
                    messagePack: messagePack
                });
            }
            return m.replymsgparent || m.transfermsgparent;
        }
    ).forEach((m: IHistoricMessage) => {
        const mountMessagePack = (msgParent: string) => {
            messagePackList.forEach((mp: { messagePack: IHistoricMessage[] }) => {
                if ( mp.messagePack.find((msg: IHistoricMessage) => msgParent === msg.resourceuid) ) {
                    const index = mp.messagePack.findIndex(el => el.date < m.date);
                    mp.messagePack.splice(index, 0, m);
                }
            })
        }

        if ( m.replymsgparent ) mountMessagePack(m.replymsgparent);
        else if ( m.transfermsgparent ) mountMessagePack(m.transfermsgparent);

    });

    return messagePackList;
}
