import { api } from "@/auth/api";
import { settings } from "@/settings";
import { Picklist } from "@/types";
import { getOperator, globalConfig, headers } from "@/utils";
import Notify from 'quasar/src/plugins/Notify.js';;
import moment from "moment";
import axios from "axios";
const odmUser = `odm-user`
const baseUrl = `${settings.api_url}/${odmUser}/api/1/${odmUser}`
const api_query_url = settings.api_query_url;

export function searchDaaqList(filter: any): Promise<Picklist> {
    return new Promise<Picklist>(async (resolve, reject) => {
      const url = settings.api_query_url;
  
      const qc_header: any = {
        qc: {
          queryId: "search-daaq",
        },
      };
      qc_header.qc.parameters = {
        ...filter,
      };
      const operators:any = [];
      
      if (filter && filter.daaq !== undefined) {
        operators.push(getOperator("lower(user_daaq", "id)", "LIKE", globalConfig.formatters.getEqualValue(filter.daaq + '%').toLowerCase(), "id"))
      }
      
      if (filter && filter.deleteAllowed !== undefined && filter.deleteAllowed.value !== undefined) {
        operators.push(
          getOperator(
            "user_daaq",
            "delete_allowed",
            "EQUAL",
            globalConfig.formatters.getEqualValue(filter.deleteAllowed.value),
            "delete_allowed"
          )
        );
      }
      if (filter && filter.createAllowed !== undefined && filter.createAllowed.value !== undefined) {
        operators.push(
          getOperator(
            "user_daaq",
            "create_allowed",
            "EQUAL",
            globalConfig.formatters.getEqualValue(filter.createAllowed.value),
            "create_allowed"
          )
        );
      }
      if (filter && filter.readAllowed !== undefined && filter.readAllowed.value !== undefined) {
        operators.push(
          getOperator(
            "user_daaq",
            "read_allowed",
            "EQUAL",
            globalConfig.formatters.getEqualValue(filter.readAllowed.value),
            "read_allowed"
          )
        );
      }
      if (filter && filter.updateAllowed !== undefined && filter.updateAllowed.value !== undefined) {
        operators.push(
          getOperator(
            "user_daaq",
            "update_allowed",
            "EQUAL",
            globalConfig.formatters.getEqualValue(filter.updateAllowed.value),
            "update_allowed"
          )
        );
      }
     
      if (operators.length > 0) {
        qc_header.qc.otherFilters = {
          expressions: [
            {
              and: {
                operators,
              },
            },
          ],
        };
        qc_header.qc.otherOrders = null;
        qc_header.qc.offset = 0;
        qc_header.qc.limit = 100;
      }
  
      const _headers = headers({
        qc: JSON.stringify(qc_header.qc),
      });
  
      axios
        .get(url, {
          headers: _headers,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
            Notify.create({
              message: `Query returned an error \n ${err}`,
              color: "negative",
            });
          });
    });
}
export function getUserByRole(filter: any): Promise<Picklist> {
    return new Promise<Picklist>(async (resolve, reject) => {
      const url = settings.api_query_url;
  
      const qc_header: any = {
        qc: {
          queryId: "user-by-role",
        },
      };
      qc_header.qc.parameters = {
        ...filter,
      };
      const operators = [];
      if (filter && filter.role != null && filter.role.value) {
        operators.push(
          getOperator(
            "party_role",
            "id",
            "EQUAL",
            globalConfig.formatters.getEqualValue(filter.role.value),
            "id"
          )
        );
      }
      if (operators.length > 0) {
        qc_header.qc.otherFilters = {
          expressions: [
            {
              and: {
                operators,
              },
            },
          ],
        };
        qc_header.qc.otherOrders = null;
        qc_header.qc.offset = 0;
        qc_header.qc.limit = 500;
      }
  
      const _headers = headers({
        qc: JSON.stringify(qc_header.qc),
      });
  
      axios
        .get(url, {
          headers: _headers,
        })
        .then((res) => {
          const response = {
            systemUid: "odm-user",
            items: res.data.map((resItem: any) => {
              return {
                id: resItem.username,
                label: resItem.username,
              };
            }),
          };
          resolve(response);
        })
        .catch((err) => {
          //FIXME: until debizium is fixed
          //reject(err)
  
          resolve({
            systemUid: "",
            items: [
              {
                id: "odm-offer",
                label: "Crée2",
              },
            ],
          });
        });
    });
  }


  export async function saveDaaq(daaq:any,edit:boolean){

    if(edit){
        delete daaq.reference
        const createdDaaq=await updateDaaq(daaq.resourceUid,daaq)
        return createdDaaq
    }else{
        daaq.resourceUid=daaq.reference
        delete daaq.reference
        const createdDaaq=await createDaaq(daaq) 
        return createdDaaq
    }

  }
  export async function  getDaaq(resourceUid: any) { 
    const daaq = await getDaaqApi(resourceUid)
    return daaq
  }

  export async function  createDaaq(daaq: any) {  
    return api().post(`${baseUrl}/daaq/`, daaq)
  }
  export async function  updateDaaq(resourceUid:any,daaq: any) {  
    return api().put(`${baseUrl}/daaq/`+resourceUid+'/', daaq)
  }

  export async function  getDaaqApi(resourceUid: any) {  
    return api().get(`${baseUrl}/daaq/`+resourceUid+'/')
  }
