import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from '../store'

import i18n from '../i18n'
import Home from "@/components/Home.vue";
import { tokenIsValid, checkUserByRole } from "@/utils";
import { HomePageRoles } from "@/types";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        component: () => import('@/components/auth/Login.vue')
    },
    {
        path: '/home',
        name: 'home',
        component: Home
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/components/auth/Login.vue')
    },
    {
        path: '/signup',
        name: 'signup',
        component: () => import('@/components/auth/Registration.vue')
    },
    {
        path: '/forgotPassword',
        name: 'forgotPassword',
        component: () => import('@/components/auth/ForgotPassword.vue')
    },
    {
        path: '/demand/list',
        name: 'demand-list',
        component: () => import('@/views/demand/DemandViewList.vue')
    },
    {
        path: '/demand/edit/:id?',
        name: 'demand-edit',
        component: () => import('@/views/demand/DemandViewEdit.vue')
    },
    {
        path: '/order/edit/:id?',
        name: 'order-edit',
        component: () => import('@/views/order/OrderViewEdit.vue')
    },
    {
        path: '/mo/demand/detail/:id?',
        name: 'mo-demand-detail',
        component: () => import('@/views/middle-office/MiddleOfficeView.vue')
    },
    {
        path: "/order",
        name: "order",
        component: () => import("@/views/order/OrderViewList.vue")
    },
    {
        path: '/third/list',
        name: 'third-list',
        component: () => import('@/views/party/ThirdViewList.vue')
    },
    {
        path: '/third/edit/:id?',
        name: 'third-edit',
        component: () => import('@/views/party/ThirdViewEdit.vue')
    },
    {
        path: '/task/list',
        name: 'task-list',
        component: () => import('@/views/tasks/TaskViewList.vue')
    },
    {
        path: '/task/detail',
        name: 'TaskDetail',
        component: () => import('@/views/tasks/TaskView.vue')
    },
    {
        path: '/scale/list',
        name: 'scale-list',
        component: () => import('@/views/configuration/scales/ScaleViewList.vue')
    },
    {
        path: '/scale/edit',
        name: 'scale-edit',
        component: () => import('@/views/configuration/scales/ScaleViewDetails.vue')
    },
    {
        path: '/profile',
        name: 'Profile',
        component: () => import('@/views/user-preference/ProfileView.vue')
    }, {
        path: '/garanty/list',
        name: 'garanty-list',
        component: () => import('@/views/configuration/garanty/GuaranteeView.vue')
    },
    {
        path: '/good/list',
        name: 'asset-list',
        component: () => import('@/views/configuration/goods/GoodViewList.vue')
    },
    {
        path: '/documentType/list',
        name: 'documentType-list',
        component: () => import('@/views/configuration/documents/documentTypeViewList.vue')
    },
    {
        path: '/documentFamily/edit',
        name: 'documentFamily-edit',
        component: () => import('@/views/configuration/documents/documentDetailsView.vue')
    },
    {
        path: '/documentFamily/list',
        name: 'documentFamily-list',
        component: () => import('@/views/configuration/documents/documentFamilyViewList.vue')
    },
    {
        path: '/action/list',
        name: 'action-list',
        component: () => import('@/views/configuration/actions/ActionsViewList.vue')
    },
    {
        path: '/action/edit',
        name: 'action-edit',
        component: () => import('@/views/configuration/actions/ActionViewDetails.vue')
    },
    {
        path: '/actionContext/list',
        name: 'actionContext-list',
        component: () => import('@/views/configuration/actions/ActionsContextViewList.vue')
    },
    {
        path: '/screnConfiguration/list',
        name: 'screnConfiguration-list',
        component: () => import('@/views/configuration/screnConfiguration/ScrenConfigurationViewList.vue')
    },
    {
        path: '/userAdmin/list',
        name: 'user-list',
        component: () => import('@/views/configuration/users/UserViewList.vue')
    },
    {
        path: '/userAdmin/edit',
        name: 'user-edit',
        component: () => import('@/views/configuration/users/UserViewDetails.vue'),
    },
    {
        path: '/good/edit',
        name: 'good-edit',
        component: () => import('@/views/configuration/goods/GoodViewDetails.vue')
    }, {
        path: '/rule',
        name: 'rule',
        component: () => import('@/views/rule.vue')
    },
    {
        path: '/availability/list',
        name: 'availability-list',
        component: () => import('@/views/configuration/availabilities/AvailabilityViewList.vue')
    },
    {
        path: '/opinion/detail',
        name: 'OpinionDetail',
        component: () => import('@/views/tasks/OpinionView.vue')
    },
    {
        path: '/analysis/detail',
        name: 'AnalysisDetail',
        component: () => import('@/views/middle-office/AnalysisView.vue')
    },
    {
        path: '/changePassword',
        name: 'changePassword',
        component: () => import('@/components/auth/ChangePassword.vue')
    },
    {
        path: '/role-menu',
        name: 'ScreenAuthority',
        component: () => import('@/views/configuration/role-menu/RoleMenuViewList.vue')
    },
    {
        path: '/deliverySchedule',
        name: 'deliverySchedule',
        component: () => import('@/views/deliverySchedule/DeliveryScheduleView.vue')
    },
    {
        path: '/objective/list',
        name: 'objective-list',
        component: () => import('@/views/objective/definition/ObjectiveViewList.vue')
    },
    {
        path: '/objectiveSales/list',
        name: 'objectiveSales-list',
        component: () => import('@/views/objective/sales/ObjectiveSalesViewList.vue')
    },
    {
        path: '/objective/gestion',
        name: 'objective-gestion',
        component: () => import('@/views/objective/gestion/GestionView.vue')
    },
    {
        path: '/objective/edit',
        name: 'objective-edit',
        component: () => import('@/views/objective/definition/ObjectiveViewDetails.vue')
    },
    {
        path: '/GestionDabsence',
        name: 'GestionDabsence',
        component: () => import('@/views/configuration/users/AbsenceManagement.vue')
    },
    {
        path: '/GestionDabsence/edit',
        name: 'GestionDabsenceEdit',
        component: () => import('@/views/configuration/users/AbsenceManagementViewDetails.vue')
    },
    {
        path: '/group/list',
        name: 'group-list',
        component: () => import('@/views/configuration/users/GroupViewList.vue')
    },
    {
        path: '/team/list',
        name: 'team-list',
        component: () => import('@/views/configuration/users/TeamViewList.vue')
    },
    {
        path: '/network/list',
        name: 'network-list',
        component: () => import('@/views/configuration/users/NetworkViewList.vue')
    },
    {
        path: '/group/edit',
        name: 'group-edit',
        component: () => import('@/views/configuration/users/GroupViewDetail.vue')
    },
    {
        path: '/team/edit',
        name: 'team-edit',
        component: () => import('@/views/configuration/users/TeamViewDetail.vue')
    },
    {
        path: '/network/edit',
        name: 'network-edit',
        component: () => import('@/views/configuration/users/NetworkViewDetail.vue')
    },
    {
        path: '/authority/list',
        name: 'authority-list',
        component: () => import('@/views/configuration/users/AuthorityViewList.vue')
    },
    {
        path: '/daaq/list',
        name: 'daaq-list',
        component: () => import('@/views/configuration/users/DaaqViewList.vue')
    },
    {
        path: '/daaq/edit',
        name: 'daaq-edit',
        component: () => import('@/views/configuration/users/DaaqViewDetail.vue')
    },
    {
        path: '/actionContext/edit',
        name: 'actionContext-edit',
        component: () => import('@/views/configuration/actions/ActionContextDetail.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if (!to.path.includes('task')) {
        store.dispatch('taskModule/setFilterByOfferReference', '')
    }
    if (to.path.search("demand") > 0) {
        store.dispatch("module", "demand");
    } else if (to.path.search("third") > 0) {
        store.dispatch("module", "third");
    } else if (to.path.search("task") > 0) {
        store.dispatch("module", "task");
    }
    else if (to.path.search("good") > 0) {
        store.dispatch("module", "good");
    }
    else if (to.path.search("order") > 0) {
        store.dispatch("module", "dossier");
    } 
    else if (to.path.search("GestionDabsence") > 0) {
        store.dispatch("module", "GestionDabsence");
    } 
    else if (to.path.search("deliverySchedule") > 0) {
        store.dispatch("module", "deliverySchedule");
    } else {
        store.dispatch("module", "home");
    }
    if ((to.path !== '/' &&
        to.path !== '/login' &&
        to.path !== '/signup' &&
        to.path !== '/forgotPassword' ) && !tokenIsValid()) {
        next('/login')
    }
    else if ((to.path === '/login' ||
        to.path === '/signup' ||
        to.path === '/forgotPassword') && tokenIsValid()) {
        
        const homePageRolesKeys = Object.keys(HomePageRoles); // Get an array of all HomePageRoles enum keys
        homePageRolesKeys.forEach((key:any) => {
            const role:any = key; // Get the enum key
            const value: any = (HomePageRoles as any)[key]
            if (checkUserByRole([key])) {
                next('/' + value); // Use the value for redirection
                return
            }
        });
     
        
        
        
    }
     else if (to.path === '/changePassword' && tokenIsValid()) {
        store.commit('authModule/setChangePassWordIn',false)
        next()
    }
    else {
        const lock = store.state.lockState
        if (lock) {
            store.state.authModule!.user.loggedIn = true
            store.dispatch("showConfirmationDialog", {
                message: i18n.global.t('main.confirmationDialog.message'),
                target: to.fullPath
            });
            next(false)
        } else {
            next()
        }
    }
})

export default router
