import { headers, getOperator, globalConfig } from "@/utils";
import { Picklist } from "@/types";
import { settings } from "@/settings";
import axios from "axios";
import Notify from 'quasar/src/plugins/Notify.js';;
import {
  formatDateLocale,
  isEmpty,
} from "@/utils/configuration/formatters-config";
import { api } from "@/auth/api";

const odm_supportingdocument = `odm-supportingdocument`;
const baseUrl = `${settings.api_url}/${odm_supportingdocument}/api/1/${odm_supportingdocument}`;

export function searchAssessmentContextList(filter: any): Promise<Picklist> {
  return new Promise<Picklist>(async (resolve, reject) => {
    const url = settings.api_query_url;

    const qc_header: any = {
      qc: {
        queryId: "supportingdocument-assessment-context",
      },
    };
    qc_header.qc.parameters = {
      ...filter,
    };
    const operators: any = [];

    if (filter && !isEmpty(filter.id)) {
      operators.push(
        getOperator(
          "supportingdocument_assessment_context",
          "id",
          "LIKE",
          globalConfig.formatters.getLikeValue(filter.id),
          "id"
        )
      );
    }

    if (filter && !isEmpty(filter.contextType)) {
      operators.push(
        getOperator(
          "supportingdocument_assessment_context",
          "context_type",
          "LIKE",
          globalConfig.formatters.getLikeValue(filter.contextType),
          "context_type"
        )
      );
    }

    if (filter && !isEmpty(filter.validity_from)) {
      operators.push(
        getOperator(
          "supportingdocument_assessment_context",
          "validity_from",
          "EQUAL",
          formatDateLocale(filter.validity_from, "DD/MM/YYYY", "fr").toString,
          "validity_from"
        )
      );
    }

    if (filter && !isEmpty(filter.validity_until)) {
      operators.push(
        getOperator(
          "supportingdocument_assessment_context",
          "validity_until",
          "EQUAL",
          formatDateLocale(filter.validity_until, "DD/MM/YYYY", "fr").toString,
          "validity_until"
        )
      );
    }

    if (operators.length > 0) {
      qc_header.qc.otherFilters = {
        expressions: [
          {
            and: {
              operators,
            },
          },
        ],
      };
      qc_header.qc.otherOrders = null;
      qc_header.qc.offset = 0;
      qc_header.qc.limit = 100;
    }

    const _headers = headers({
      qc: JSON.stringify(qc_header.qc),
    });

    axios
      .get(url, {
        headers: _headers,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        Notify.create({
          message: `Query returned an error ${err}`,
          color: "negative",
        });
      });
  });
}

export function getActionContext(resourceUid: string) {
  return new Promise((resolve, reject) => {
    const url = `${baseUrl}/assessmentcontext/${resourceUid}/`;
    api()
      .get(url)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        Notify.create({
          message: `Error ${err}`,
          color: "negative",
        });
      });
  });
}

export async function createActionContext(payload: any) {
  const url = `${baseUrl}/assessmentcontext/`;
  const body = {
    "resourceUid": payload.id,
    "objectType": "odm.supportingdocument.assessmentcontext",
    "systemUid": "odm-supportingdocument",
    "businessData": null,
    "daaq": "/",
    "contextType": {
      "rawValue": payload.contextType
    },
    "validity": {
      "from": !isEmpty(payload.validity_from) ? formatDateLocale(payload.validity_from, "DD/MM/YYYY", "fr", "YYYY-MM-DD") : null,
      "until": !isEmpty(payload.validity_until) ? formatDateLocale(payload.validity_until, "DD/MM/YYYY", "fr", "YYYY-MM-DD") : null,
    },
    "intRule": !isEmpty(payload.rule.int_rule_rule) ? {
      "ruleEngineId": null,
      "rule": JSON.parse(payload.rule.int_rule_rule)
    } : null,
    "reevaluateRule": !isEmpty(payload.rule.reevaluate_rule_rule) ? {
      "ruleEngineId": null,
      "rule": JSON.parse(payload.rule.reevaluate_rule_rule)
    } : null,
    "closingRule": !isEmpty(payload.rule.closing_rule_rule) ? {
      "ruleEngineId": null,
      "rule": JSON.parse(payload.rule.closing_rule_rule)
    } : null
  }
  const data = await api().post(url, body);
  return data
}

export async function updateActionContext(payload: any) {
  const url = `${baseUrl}/assessmentcontext/${payload.id}/`;
  const body = {
    "resourceUid": payload.id,
    "objectType": "odm.supportingdocument.assessmentcontext",
    "systemUid": "odm-supportingdocument",
    "businessData": null,
    "daaq": "/",
    "contextType": {
      "rawValue": payload.contextType
    },
    "validity": {
      "from": formatDateLocale(payload.validity_from, "DD/MM/YYYY", "fr", "YYYY-MM-DD"),
      "until": formatDateLocale(payload.validity_until, "DD/MM/YYYY", "fr", "YYYY-MM-DD"),
    },
    "intRule": {
      "ruleEngineId": null,
      "rule": JSON.parse(payload.rule.int_rule_rule)
    },
    "reevaluateRule": {
      "ruleEngineId": null,
      "rule": JSON.parse(payload.rule.reevaluate_rule_rule)
    },
    "closingRule": {
      "ruleEngineId": null,
      "rule": JSON.parse(payload.rule.closing_rule_rule)
    }
  }
  const data = await api().put(url, body);
  return data
}

