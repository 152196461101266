import { LeaseForgeUser, UserState } from "@/types";
import { Module } from "vuex";
import {
  SaveUser,
  SaveParty,
  buildSelectedUser,
  getTeamFromUserId,
  getTeamMembersByManagerCode,
  userList,
  getManagerByTeam,
  UpdateTeamMembers,
  savePartyRoleNetwork,
  getPerson,
  resetPasswordFromAdmin,
  addUserAuthorities,
  getUserLogs,
  getPartyRoleNetwork,
  updatePartyRoleNetwork,
  createGroup,
  saveGroup,
  groupList,
  getSecurityGroup,
  updateGroup,
} from "@/store/services/user/userService";
import { getUser, signUp } from "@/store/services/auth";
import Notify from 'quasar/src/plugins/Notify.js';;
import store from "@/store";
import { deepClone } from "@/utils";
import i18n from "@/i18n";
import {
  getNetwork,
  getNetworkNode,
  getNetworkNodeAll,
} from "@/store/services/offerService";
import router from "@/router";

let rolesParty : any
const userModule: Module<UserState, any> = {
  
  namespaced: true,
  state: {
    mode: "add",
    selectedUserDetails: {},
    team: {},
    teamMembers: [],
    userParty: {},
    managers: [],
    networkDetails: {
      network: {},
      networkNode: {},
      networkNodePicklist: [],
    },
    temporaryPassword: "",
    userLogs: [],
    selectedGroup:{
      authorities: [],
      groupDAAQs: [],
      objectType: "odm.user.securitygroup",
      systemUid: "odm-user",
      resourceUid: "",
      reference: "",
      daaq: "/",
      validity: {
        from: "",
        until: "",
      }
    }
  },

  getters: {
    getUserselectedInit() {
      return {
        objectType: "odm.user.leaseforgeuser",
        systemUid: "odm-user",
        businessData: null,
        daaq: "/",
        username: "",
        applicationName: "TWDDistribution",
        firstName: "",
        lastName: "",
        email: "",
        associatedParty: {
          objectType: "odm.party.party.person",
          systemUid: "odm-party",
          resourceUid: "",
        },
        personalAuthorities: [],
        userGroups: [

        ],
        personalDAAQs: [],
        accountNotExpired: null,
        accountNotLocked: null,
        credentialsNotExpired: null,
        enabled: null,
        defaultLanguage: null,
        preferredLanguages: [],

      };
    },
    getPartyInit() {
      return {
        objectType: "odm.party.party.person",
        systemUid: "odm-party",
        businessData: null,
        daaq: "/",
        reference: null,
        taxID: null,
        taxGeographicZone: null,
        classifications: [],
        roles: [
          {
            objectType: "odm.party.partyrole",
            role: {
              objectType: "odm.party.role",
              resourceUid: "EMPLOYEE",
            },
            status: {
              objectType: "odm.party.rolestatus",
              resourceUid: "ACTIVATED",
            },
            daaq: "/",
          },
        ],
        contacts: [],
        addresses: [],
        statusHistory: [],
        accounts: [],
        correspondents: [],
        financialExposures: [],
        generatedDocuments: [],
        currentStatus: null,
        title: null,
        firstName: "",
        middleName: null,
        familyName: "",
        birthDate: "1970-01-01",
        birthplace: null,
        nbOfChildren: null,
        gender: null,
        nbOfDependent: null,
        maritalStatuses: [],
        citizenships: [],
        ids: [],
        revenues: [],
        jobs: [],
        legalEntity: null,
      };
    },
    getTeamInit() {
      return {}
    },
    getTeamMembersInit() {
      return []
    },
    getnetworkDetailsInit() {
      return {
        networkDetails: {
          network: {},
          networkNode: {},
          networkNodePicklist: [],
        }
      }
    }
  },

  actions: {
    /**
     * Searches for user based on the given filter.
     * @param {object} context - The context object.
     * @param {object} payload - The payload object containing the filter and a callback function.
     * @return {void}
     */
    searchUser(context, payload) {
      // Gets the callback function from the payload.
      const { filter,offset , limit, callback } = payload;
      // Calls the `userList()` function to fetch the list of user.
      userList(filter,offset,limit)
        .then((response) => {
          // Passes the response to the callback function.
          callback(response);
        })
        .catch((err) => {
          // Logs the error to the console.
          console.error(err);
        });
    },
    searchGroup(context, payload) {
      const { filter,offset , limit, callback } = payload;
      groupList(filter)
        .then((response) => {
          callback(response);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    setMode({ commit }, payload) {
      commit("setMode", payload);
    },
    resetUser({ commit }, payload) {
      commit("resetUser", payload);
    },

    updatePassword({ commit }, payload) {
      commit("updatePassword", payload);
    },
    updatePasswordHandler({ commit }, payload) {
      resetPasswordFromAdmin(payload.username, payload.newPassword).then(() => {
        store.state.userModule.selectedUserDetails.temporaryPassword = true
      })
        .catch((e: any) => console.error(e));
    },
    updateTeam({ commit }, payload) {
      commit("updateTeam", payload);
    },
    executeSelectUser({ commit }, payload) {
      const { mode, user, callback } = payload;

      getUser(user.id)
        .then(async (user) => {
          if (user?.data) {

            getTeamFromUserId(user.data.resourceUid)
              .then((res) => {

                commit("setTeam", res[0]);
                if (res && res.length > 0) {
                  getTeamMembersByManagerCode(
                    store.state.userModule.team.config.manager_code
                  )
                    .then((response) => {
                      commit("setTeamMembers", response);
                    })
                    .catch((e: any) => console.error(e));
                }
              })
              .catch((e: any) => console.error(e));

            const userSelected = await buildSelectedUser(user.data, payload.user);
            store.state.userModule.selectedUserDetails.resourceUid = userSelected.resourceUid
            if (mode && mode === "add") {
              store.state.userModule.selectedUserDetails.userGroups.forEach((item: any) => delete item.resourceUid)
            }
           



           await  getPerson(user.data.associatedParty?.resourceUid).then((person) => {
              store.state.userModule.userParty = person
              if (mode && mode === "add") {
                store.state.userModule.selectedUserDetails.roles.forEach((r:any) => {
                  let role = {
                    objectType: "odm.party.partyrole",
                    role: {
                      objectType: "odm.party.role",
                      resourceUid: r.value,
                    },
                    status: {
                      objectType: "odm.party.rolestatus",
                      resourceUid: "ACTIVATED",
                    },
                    daaq: "/",
                  }
                  store.state.userModule.userParty.roles.push(role)
                });

                


                  store.dispatch("userModule/createUser", {
                    notifyType: "Addsuccess",
                    role: rolesParty,
                    callback: () => { }
                  });
              }
              
              userSelected.roles=[]
                for (const role of person.roles){
                  userSelected.roles.push({
                    label :   i18n.global.t("demand.party.roles.".concat(role.role.resourceUid)),
                    value : role.role.resourceUid   
                  })
                }
              
              
              commit("saveUser", userSelected);
              
  

              getUserLogs(userSelected.username).then((res) => {
                commit("setUserLogs", res);
              }).catch((e: any) => console.error(e));
            }).catch((e: any) => console.error(e));


            callback();
          }
        })
        .catch((err) => {
          Notify.create({
            message: `Error searching Good \n ${err}`,
            color: "negative",
          });
        });
    },
    getManagerByTeam({ commit }, payload) {
      getManagerByTeam(payload)
        .then((res: any) => {
          commit("setManager", res[0]);
        })
        .catch((e: any) => console.error(e));
    },
    resetUserParty({ commit }, payload) {
      commit("resetUserParty", payload);
    },
    setManagers({ commit }, payload) {
      const managers = payload.map((el: { id: string; manager_code: string }) => {
        return {
          team_id: el.id,
          manager_code: el.manager_code,
        };
      });

      commit("setManagers", managers);
    },
    network({ commit }, payload) {
      const { filter, callback } = payload;
      getNetwork(filter)
        .then((res) => {
          // console.log('setNetwork', res)
          callback(res);
        })
        .catch((e) => console.error(e));
    },
    networknode({ commit }, payload) {
      const { filter, callback } = payload;
      getNetworkNode(filter)
        .then((res) => {
          callback(res);
        })
        .catch((e) => console.error(e));
    },

    async saveUser({ commit }, payload) {
      const { user, callback } = payload;
      const userDetails = deepClone(user);
       rolesParty = userDetails.roles

      let createUserPayload = {
        notifyType: "",
        callback: callback
      }
      delete userDetails.roles
      if (!userDetails.resourceUid) {
        createUserPayload.notifyType = "Addsuccess";

        const userPayload = {
          username: userDetails.username,
          firstName: userDetails.firstName,
          lastName: userDetails.lastName,
          email: userDetails.email,
          // password: store.state.userModule.temporaryPassword,
          password: "ERTXMp@123",
          applicationName: "TWDDistribution",
          phoneNumber: userDetails.phoneNumber,
          daaqList: "/"
        }
        signUp(userPayload).then(async res => {
          // res.smsCode = '';
          // res.username = username;
          store.dispatch("userModule/updatePasswordHandler", {
            username: userDetails.username,
            newPassword: store.state.userModule.temporaryPassword,
          }); // Commit mutation to update state
          await store.dispatch("userModule/executeSelectUser", {
            mode: "add",
            user: {
              id: res.userId
            },
            callback: () => {
            },
          });
          setTimeout(() => {
            commit("setNewCreatedUser", true);
          }, 2000);
          
          Notify.create({
            message: i18n.global.t('main.dialog.successUser', { username: userDetails.username }),
            color: 'positive'
          });
        }).catch(err => {
          console.log(err);
        });
      } else {
        createUserPayload.notifyType = "Editsuccess";
        store.dispatch("userModule/createUser", {
          notifyType: createUserPayload.notifyType,
          role: rolesParty,
          callback: () => {
            store.dispatch("releaseLockState").then(() => {
              commit("setNewCreatedUser", true)
            })

          }
        });
      }
      // commit("UpdateUserParty", userDetails);

    },

    async createUser({ commit }, payload) {
      const { notifyType, callback, role } = payload;

      await SaveParty(store.state.userModule.userParty, role)
        .then(async (res: any) => {
          commit("saveUserParty", res);
          store.state.userModule.selectedUserDetails.associatedParty.resourceUid =
            res.resourceUid;

          for(const roles of  res.roles ){
              const rolePayload = {
                body: {
                  "objectType": "odm.party.partyrolenetwork",
                  "systemUid": "odm-party",
                  "businessData": null,
                  "daaq": "/",
                  "partyRole": {
                    "objectType": "odm.party.partyrole",
                    "systemUid": "odm-party",
                    "resourceUid": roles.resourceUid
                  }

                },
                networkNode: store.state.userModule.networkDetails.networkNode.config.id
              }
              //  if (store.state.userModule.mode === 'add') {
              //     const partyRoleRes = await savePartyRoleNetwork(rolePayload)
              //  }
              //  else{
              //   const {response} = await getPartyRoleNotworkNode()
              //  }
              const allPartyRoleRes = await getPartyRoleNetwork(rolePayload)
              const listPartyRoleRes = allPartyRoleRes.data.filter((s:any) => s.partyRole.resourceUid === roles.resourceUid)
              if (listPartyRoleRes.length === 0){
                const partyRoleRes = await savePartyRoleNetwork(rolePayload)
              }
              else if (listPartyRoleRes.length === 1){
                const partyRoleRes = await updatePartyRoleNetwork(listPartyRoleRes[0])
              }
           }

           const userDetails = store.state.userModule.selectedUserDetails
           delete userDetails.roles
          SaveUser(userDetails)
            .then((user: any) => {
              UpdateTeamMembers(user);
              if (store.state.userModule.mode === 'add') {
                addUserAuthorities(user.resourceUid)
                  .then((authorities: any) => {
                    console.log("authorities", authorities)
                  })
                  .catch((e) => console.error(e));
              }
              Notify.create({
                message: i18n.global.t("user.dialog." + notifyType),
                color: "positive",
              });
              callback()
            })
            .catch((err: any) => {
              console.error(err);
              Notify.create({
                message: i18n.global.t("user.dialog.error"),
                color: "negative",
              });
            });
        })
        .catch((err: any) => {
          console.error(err);
          Notify.create({
            message: i18n.global.t("user.dialog.error"),
            color: "negative",
          });
        });
    },
    createGroup({ state, commit }, payload) {
      const { group} = payload
      group.resourceUid=group.reference
      delete group.reference
      saveGroup(group).then((res:any) => {
        Notify.create({
            message: "Group Created...",
            color: "positive",
        })
        res.data.authorities = res.data.authorities.map((authorityObj:any) => authorityObj.authority.resourceUid);
        commit('setgroup', res.data);
        
    })
    .catch((e:any) => console.error(e))
        },
        updateGroup({ state, commit }, payload) {
          const { group} = payload
          delete group.reference
          updateGroup(group).then((res:any) => {
            Notify.create({
                message: "Group Updated...",
                color: "positive",
            })
            res.data.authorities = res.data.authorities.map((authorityObj:any) => authorityObj.authority.resourceUid);
            commit('setgroup', res.data);
            
        })
        .catch((e:any) => console.error(e))
            },
        async getGroupEditMode({ commit}, payload) {
          const { groupid } = payload
          if (!groupid) {
              throw new Error('ResourceUid is mandatory!')
          }
          try {
              const group = await getSecurityGroup(groupid)
              group.data.authorities = group.data.authorities.map((authorityObj:any) => authorityObj.authority.resourceUid);
              if (!group.data.validity){
                group.data.validity = {
                  from: "",
                  until: "",
                }
              }
              commit('setgroup', group.data);
              router.push("/group/edit");
          } catch (e) {
              Notify.create({
                  message: 'ERROR: ' + e,
                  color: 'negative'
              })
          }
        },

    initUserModule({ commit }) {
      commit("initUserModule");
    },
    cleanGroup({ commit }) {
      commit("initGroup");
    }
  },
  mutations: {
    setUserLogs(state, payload) {
      state.userLogs = payload
    },
    setMode(state, payload) {
      state.mode = payload;
    },
    setNewCreatedUser(state, payload) {
      state.newCreatedUser = payload
    },
    resetUser(state, payload) {
      if (state.selectedUserDetails) state.selectedUserDetails = payload;
    },
    saveUser(state, payload) {
      state.selectedUserDetails = payload;
    },
    setTeam(state, payload) {
      state.team = payload;
    },
    setManager(state, payload) {
      state.manager = payload;
    },
    setTeamMembers(state, payload) {
      state.teamMembers = payload;
    },
    resetUserParty(state, payload) {
      if (state.userParty) state.userParty = payload;
    },
    UpdateUserParty(state, payload) {
      state.userParty.firstName = payload.firstName;
      state.userParty.familyName = payload.lastName;
    },
    updatePassword(state, payload) {
      state.temporaryPassword = payload;
    },
    updateTeam(state, payload) {
      state.team = payload;
    },
    setManagers(state, payload) {
      state.managers = payload;
    },
    setNetwork(state, payload) {
      state.networkDetails.network = payload;
    },
    saveUserParty(state, payload) {
      if (state.userParty) state.userParty = payload;
    },
    UpdateassociatedParty(state, payload) {
      if (state.selectedUserDetails) {
        state.selectedUserDetails.associatedParty = payload;
      }
    },
    initUserModule(state) {
      const userPartyGetter = store.getters["userModule/getPartyInit"];
      store.dispatch("userModule/resetUserParty", userPartyGetter);
      const userGetter = store.getters["userModule/getUserselectedInit"]
      store.dispatch("userModule/resetUser", userGetter);
      state.team = {};
      state.teamMembers = [];
      state.networkDetails = {
        network: {},
        networkNode: {},
        networkNodePicklist: [],
      }
    },
    initGroup(state) {
      state.selectedGroup={
        authorities: [],
        groupDAAQs: [],
        objectType: "odm.user.securitygroup",
        systemUid: "odm-user",
        resourceUid: "",
        reference: "",
        daaq: "/",
        validity: {
          from: "",
          until: "",
        }
      }

    },
    setgroup(state, payload) {
      payload.reference=payload.resourceUid
      state.selectedGroup=payload
    }
  },
};
export default userModule;
