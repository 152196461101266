import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode, mergeProps as _mergeProps, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card-body"
}
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-lg-2 col-md-12 col-sm-12 form-control no-border" }
const _hoisted_4 = { class: "d-block mb-0 small text-muted mb-2" }
const _hoisted_5 = { class: "col-lg-4 col-md-12 col-sm-12 form-control no-border" }
const _hoisted_6 = { class: "d-block mb-0 small text-muted mb-2" }
const _hoisted_7 = { class: "col-lg-4 col-md-12 col-sm-12 form-control no-border" }
const _hoisted_8 = { class: "d-block mb-0 small text-muted mb-2" }
const _hoisted_9 = { class: "col-lg-2 col-md-12 col-sm-12 form-control no-border" }
const _hoisted_10 = { class: "d-block mb-0 small text-muted mb-2" }
const _hoisted_11 = { class: "row items-center justify-end" }
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { class: "col-lg-1 form-control no-border mt-3" }
const _hoisted_14 = { class: "col-lg-5 form-control no-border mt-2" }
const _hoisted_15 = { class: "mr-2 font-weight-normal" }
const _hoisted_16 = { class: "theme-1" }
const _hoisted_17 = { class: "d-flex align-items-center" }
const _hoisted_18 = { class: "badge badge-primary" }
const _hoisted_19 = { class: "ml-2" }
const _hoisted_20 = { class: "col-lg-1 form-control no-border mt-3" }
const _hoisted_21 = { class: "col-lg-5 form-control no-border mt-2" }
const _hoisted_22 = { class: "mr-2 font-weight-normal" }
const _hoisted_23 = { class: "theme-1" }
const _hoisted_24 = { class: "d-flex align-items-center" }
const _hoisted_25 = { class: "badge badge-primary" }
const _hoisted_26 = { class: "ml-2" }
const _hoisted_27 = { class: "row" }
const _hoisted_28 = { class: "col-lg-6 form-control no-border" }
const _hoisted_29 = { class: "d-block mb-0 small text-muted" }
const _hoisted_30 = { class: "row" }
const _hoisted_31 = { class: "col-lg-12 form-control no-border mt-2" }
const _hoisted_32 = { class: "d-block mb-0 small text-muted mb-2" }
const _hoisted_33 = { class: "row" }
const _hoisted_34 = { class: "col-lg-6 col-md-12 col-sm-12 form-control no-border" }
const _hoisted_35 = { class: "d-block mb-0 small text-muted" }
const _hoisted_36 = { class: "mr-2 font-weight-normal" }
const _hoisted_37 = { class: "theme-1" }
const _hoisted_38 = { class: "d-flex align-items-center" }
const _hoisted_39 = { class: "badge badge-primary" }
const _hoisted_40 = { class: "ml-2" }
const _hoisted_41 = { class: "col-lg-4 col-md-12 col-sm-12 form-control no-border mt-4" }
const _hoisted_42 = { class: "text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_toggle = _resolveComponent("q-toggle")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_date = _resolveComponent("q-date")!
  const _component_q_popup_proxy = _resolveComponent("q-popup-proxy")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_chip = _resolveComponent("q-chip")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _directive_close_popup = _resolveDirective("close-popup")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_ctx.show)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t( "communication.alertMessage.newMessage" )), 1),
            _createVNode(_component_q_toggle, {
              modelValue: _ctx.importantTag,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.importantTag) = $event)),
              color: "red",
              class: "tw-custom-toggle ml-2"
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t( "communication.alertMessage.reference" )), 1),
            _createVNode(_component_q_input, {
              dense: "",
              outlined: "",
              modelValue: _ctx.reference,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.reference) = $event)),
              disable: "",
              loading: !_ctx.reference
            }, null, 8, ["modelValue", "loading"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t( "communication.alertMessage.subject" )), 1),
            _createVNode(_component_q_select, {
              outlined: "",
              "bottom-slots": "",
              modelValue: _ctx.subject,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.subject) = $event)),
              options: _ctx.subjects,
              rules: [this.isRequired],
              dense: "",
              "options-dense": "",
              loading: !_ctx.subjects
            }, {
              append: _withCtx(() => [
                (_ctx.subject)
                  ? (_openBlock(), _createBlock(_component_q_icon, {
                      key: 0,
                      name: "cancel",
                      size: "xs",
                      class: "cursor-pointer",
                      onClick: [
                        _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop","prevent"])),
                        _cache[3] || (_cache[3] = ($event: any) => (_ctx.subject = null))
                      ]
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["modelValue", "options", "rules", "loading"])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t( "communication.alertMessage.dateMessage")), 1),
            _createVNode(_component_q_input, {
              readonly: "",
              modelValue: _ctx.dateMessage,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.dateMessage) = $event)),
              outlined: "",
              dense: ""
            }, {
              append: _withCtx(() => [
                _createVNode(_component_q_icon, {
                  name: "event",
                  class: "cursor-pointer"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_popup_proxy, {
                      ref: "qDateProxy",
                      cover: "",
                      "tranitsion-show": "scale",
                      "transition-hide": "scale"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_date, {
                          modelValue: _ctx.dateAvis,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.dateAvis) = $event)),
                          mask: _ctx.$t('mask.format'),
                          readonly: ""
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_11, [
                              _withDirectives(_createVNode(_component_q_btn, {
                                label: _ctx.$t(
        'task.completeDocument.party.person.financialSituation.close'
    )
        ,
                                color: "primary",
                                flat: ""
                              }, null, 8, ["label"]), [
                                [_directive_close_popup]
                              ])
                            ])
                          ]),
                          _: 1
                        }, 8, ["modelValue", "mask"])
                      ]),
                      _: 1
                    }, 512)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modelValue"])
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_q_checkbox, {
              modelValue: _ctx.isInternRecipient,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.isInternRecipient) = $event)),
              dense: "",
              label: 'interne',
              class: "small mr-3"
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createVNode(_component_q_select, {
              outlined: "",
              "use-input": "",
              multiple: "",
              modelValue: _ctx.recipient,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.recipient) = $event)),
              options: _ctx.optionsRecipients,
              rules: [this.isComboRequired],
              onFilter: _ctx.filterDest,
              onClick: _withModifiers(_ctx.abortDestFilter, ["stop","prevent"]),
              dense: "",
              "options-dense": "",
              "emit-value": "",
              "map-options": "",
              loading: !_ctx.optionsRecipients
            }, {
              "selected-item": _withCtx((scope) => [
                _createVNode(_component_q_chip, {
                  removable: "",
                  dense: "",
                  onRemove: ($event: any) => (scope.removeAtIndex(scope.index)),
                  tabindex: scope.tabindex,
                  color: "white",
                  "text-color": "primary",
                  class: "badge badge-primary d-flex",
                  onClick: _withModifiers(_ctx.abortDestFilter, ["stop","prevent"])
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.$t(
                      "communication.comMessage.".concat(
                        _ctx.getSelectedRecipientLabel(scope).context
                      )
                    )), 1),
                    _createTextVNode(" - " + _toDisplayString(_ctx.getSelectedRecipientLabel(scope).id), 1)
                  ]),
                  _: 2
                }, 1032, ["onRemove", "tabindex", "onClick"])
              ]),
              option: _withCtx((scope) => [
                _createElementVNode("div", _hoisted_16, [
                  _withDirectives((_openBlock(), _createElementBlock("div", _mergeProps({
                    class: "border p-2",
                    key: _ctx.getSelectedRecipientLabel(scope),
                    clickable: ""
                  }, scope.itemProps), [
                    _createElementVNode("div", _hoisted_17, [
                      _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.$t(
                            "communication.comMessage.".concat(
                              _ctx.getSelectedRecipientLabel(scope).context
                            )
                          )), 1),
                      _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.getSelectedRecipientLabel(scope).id), 1)
                    ])
                  ], 16)), [
                    [_directive_ripple],
                    [_directive_close_popup]
                  ])
                ])
              ]),
              "no-option": _withCtx(() => [
                _createVNode(_component_q_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_item_section, { class: "text-grey" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("main.noResults")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modelValue", "options", "rules", "onFilter", "onClick", "loading"])
          ]),
          _createElementVNode("div", _hoisted_20, [
            _createVNode(_component_q_checkbox, {
              modelValue: _ctx.isExternRecipient,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.isExternRecipient) = $event)),
              dense: "",
              label: 'externe',
              class: "small mr-3"
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_21, [
            _createVNode(_component_q_select, {
              outlined: "",
              "use-input": "",
              multiple: "",
              modelValue: _ctx.associatedRecipient,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.associatedRecipient) = $event)),
              options: _ctx.orderParties,
              onFilter: _ctx.filterAssociatedDest,
              onClick: _withModifiers(_ctx.abortAssociatedDestFilter, ["stop","prevent"]),
              dense: "",
              "options-dense": "",
              "emit-value": "",
              "map-options": "",
              loading: !_ctx.orderParties
            }, {
              "selected-item": _withCtx((scope) => [
                _createVNode(_component_q_chip, {
                  removable: "",
                  dense: "",
                  onRemove: ($event: any) => (scope.removeAtIndex(scope.index)),
                  tabindex: scope.tabindex,
                  color: "white",
                  "text-color": "primary",
                  class: "badge badge-primary d-flex",
                  onClick: _withModifiers(_ctx.abortAssociatedDestFilter, ["stop","prevent"])
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.$t(
                      "communication.comMessage.".concat(
                        _ctx.getSelectedAssociatedRecipientLabel(scope).context
                      )
                    )), 1),
                    _createTextVNode(" - " + _toDisplayString(_ctx.getSelectedAssociatedRecipientLabel(scope).id), 1)
                  ]),
                  _: 2
                }, 1032, ["onRemove", "tabindex", "onClick"])
              ]),
              option: _withCtx((scope) => [
                _createElementVNode("div", _hoisted_23, [
                  _withDirectives((_openBlock(), _createElementBlock("div", _mergeProps({
                    class: "border p-2",
                    key: _ctx.getSelectedAssociatedRecipientLabel(scope),
                    clickable: ""
                  }, scope.itemProps), [
                    _createElementVNode("div", _hoisted_24, [
                      _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.$t(
                            "communication.comMessage.".concat(
                              _ctx.getSelectedAssociatedRecipientLabel(scope).context
                            )
                          )), 1),
                      _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.getSelectedAssociatedRecipientLabel(scope).id), 1)
                    ])
                  ], 16)), [
                    [_directive_ripple],
                    [_directive_close_popup]
                  ])
                ])
              ]),
              "no-option": _withCtx(() => [
                _createVNode(_component_q_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_item_section, { class: "text-grey" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("main.noResults")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modelValue", "options", "onFilter", "onClick", "loading"])
          ])
        ]),
        _createElementVNode("div", _hoisted_27, [
          _createElementVNode("div", _hoisted_28, [
            _createElementVNode("label", _hoisted_29, _toDisplayString(_ctx.$t( "communication.alertMessage.cc")), 1),
            _createVNode(_component_q_input, {
              dense: "",
              outlined: "",
              modelValue: _ctx.cc,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.cc) = $event)),
              rules: _ctx.emailRules()
            }, null, 8, ["modelValue", "rules"])
          ])
        ]),
        _createElementVNode("div", _hoisted_30, [
          _createElementVNode("div", _hoisted_31, [
            _createElementVNode("label", _hoisted_32, _toDisplayString(_ctx.$t( "communication.alertMessage.message" )), 1),
            _createVNode(_component_q_input, {
              modelValue: _ctx.message,
              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.message) = $event)),
              outlined: "",
              type: "textarea",
              rows: "1",
              "error-message": _ctx.$t('communication.comMessage.missingField'),
              error: !_ctx.message && _ctx.message?.length <= 0,
              placeholder: _ctx.$t('communication.alertMessage.messagePlaceholder')
            }, null, 8, ["modelValue", "error-message", "error", "placeholder"])
          ])
        ]),
        _createElementVNode("div", _hoisted_33, [
          _createElementVNode("div", _hoisted_34, [
            _createElementVNode("label", _hoisted_35, _toDisplayString(_ctx.$t( "communication.alertMessage.orderDocuments")), 1),
            _createVNode(_component_q_select, {
              outlined: "",
              "use-input": "",
              multiple: "",
              modelValue: _ctx.documents,
              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.documents) = $event)),
              options: _ctx.partyDocuments,
              onFilter: _ctx.filterPartyDocument,
              onClick: _withModifiers(_ctx.abortPartyDocumentFilter, ["stop","prevent"]),
              dense: "",
              "options-dense": "",
              "emit-value": "",
              "map-options": "",
              loading: !_ctx.partyDocuments
            }, {
              "selected-item": _withCtx((scope) => [
                _createVNode(_component_q_chip, {
                  removable: "",
                  dense: "",
                  onRemove: ($event: any) => (scope.removeAtIndex(scope.index)),
                  tabindex: scope.tabindex,
                  color: "white",
                  "text-color": "primary",
                  class: "badge badge-primary d-flex",
                  onClick: _withModifiers(_ctx.abortPartyDocumentFilter, ["stop","prevent"])
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_36, _toDisplayString(_ctx.$t(
                      "communication.comMessage.".concat(
                        _ctx.getSelectedDocumentLabel(scope).context
                      )
                    )), 1),
                    _createTextVNode(" - " + _toDisplayString(_ctx.getSelectedDocumentLabel(scope).id), 1)
                  ]),
                  _: 2
                }, 1032, ["onRemove", "tabindex", "onClick"])
              ]),
              option: _withCtx((scope) => [
                _createElementVNode("div", _hoisted_37, [
                  _withDirectives((_openBlock(), _createElementBlock("div", _mergeProps({
                    class: "border p-2",
                    key: _ctx.getSelectedDocumentLabel(scope),
                    clickable: ""
                  }, scope.itemProps), [
                    _createElementVNode("div", _hoisted_38, [
                      _createElementVNode("span", _hoisted_39, _toDisplayString(_ctx.$t(
                            "communication.comMessage.".concat(
                              _ctx.getSelectedDocumentLabel(scope).context
                            )
                          )), 1),
                      _createElementVNode("span", _hoisted_40, _toDisplayString(_ctx.getSelectedDocumentLabel(scope).id), 1)
                    ])
                  ], 16)), [
                    [_directive_ripple],
                    [_directive_close_popup]
                  ])
                ])
              ]),
              "no-option": _withCtx(() => [
                _createVNode(_component_q_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_item_section, { class: "text-grey" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("main.noResults")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modelValue", "options", "onFilter", "onClick", "loading"])
          ]),
          _createElementVNode("div", _hoisted_41, [
            _createElementVNode("input", {
              type: "file",
              id: "fileUpload",
              onChange: _cache[14] || (_cache[14] = 
//@ts-ignore
(...args) => (_ctx.onSelectFile && _ctx.onSelectFile(...args))),
              accept: ".pdf,.jpg,.jpeg,.png"
            }, null, 32)
          ])
        ]),
        _createElementVNode("div", _hoisted_42, [
          _createVNode(_component_q_btn, {
            label: _ctx.$t('communication.comMessage.sendBtn'),
            class: "btn btn-primary",
            onClick: _ctx.sendMessage,
            disable: _ctx.onDisable
          }, null, 8, ["label", "onClick", "disable"])
        ])
      ]))
    : _createCommentVNode("", true)
}